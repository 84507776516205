import React, { useState } from "react";

const Container = (props) => {
  return (
    <div id="wrapper">
      <div id="loader-wrapper">
        <div className="loader-section section-left"></div>
        <div className="loader-section section-right"></div>
      </div>

      <div className="main-content">{props.children}</div>
    </div>
  );
};

export default Container;
