import axios from "axios";
import { baseUrl } from "./apiConstant";

export function makeRequest(url, type = "get", data = {}, header = {}) {
  if (typeof window !== "undefined") {
    var path = window.location.protocol + "//" + window.location.host + "/v1/";
    axios.defaults.baseURL = path;
    console.log("path: ", path);
  }

  let reqHeader = Object.assign(header, {
    "Content-Type": "application/json",
  });
  let getHeader = header;

  console.log("call->", url, type, reqHeader, getHeader);
  if (type === "get") {
    return axios
      .get(url, { headers: getHeader, params: data })
      .then((response) => {
        return Promise.resolve(response);
      })
      .catch((err) => {
        console.log("err->", url);
        return Promise.resolve(err.response);
      });
  } else if (type === "post") {
    return axios
      .post(url, data, { headers: reqHeader })
      .then((response) => {
        return Promise.resolve(response);
      })
      .catch((err) => {
        console.log("err->", url);
        console.log("err->", err);
        return Promise.resolve(err.response);
      });
  } else if (type === "delete") {
    return axios
      .delete(url, { headers: reqHeader })
      .then((response) => {
        return Promise.resolve(response);
      })
      .catch((err) => {
        console.log("err->", url);
        return Promise.reject(err);
      });
  } else if (type === "patch") {
    return axios
      .patch(url, data, { headers: reqHeader })
      .then((response) => {
        return Promise.resolve(response);
      })
      .catch((err) => {
        console.log("err->", url);
        return Promise.reject(err);
      });
  }
}

export function multipartPostRequest(url, method, data, header) {
  let reqHeader = Object.assign(header, { "Content-Type": "application/json" });
  console.log("call->", method, url, reqHeader, data);
  return axios
    .post(url, data, { headers: reqHeader })
    .then((response) => {
      return Promise.resolve(response);
    })
    .catch((err) => {
      return Promise.reject(err);
    });
}

export function uploadImage(url, data = {}, header = {}) {
  let reqHeader = Object.assign(header, {
    "Content-Type": "multipart/form-data",
    Accept: "application/json",
  });
  return axios
    .post(url, data, { headers: reqHeader })
    .then((response) => {
      return Promise.resolve(response);
    })
    .catch((err) => {
      return Promise.reject(err);
    });
}
