import { makeRequest } from "../../api/apiCall";
import { messagePerformance } from "../../api/apiConstant";

//Message Actions
export const getMessagePerfomance = (userData) => {
  return (dispatch, getState) => {
    try {
      return makeRequest(messagePerformance, "get", userData)
        .then((response) => {
          console.log("Message Perfomance Data: " + response.data);
          return Promise.resolve({
            status: true,
            data: response.data || [],
            message: "",
          });
        })
        .catch((error) => {
          console.log("Error: ", error);
          return Promise.reject(error);
        });
    } catch (e) {
      return Promise.reject(e);
    }
  };
};
