import React, { Fragment, useEffect, useState } from "react";
import {
  BrowserRouter as Router,
  Route,
  Redirect,
  Switch,
} from "react-router-dom";
import { Provider } from "react-redux";
import { ToastProvider } from "react-toast-notifications";

import store from "./redux/store";
import axios from "axios";

import Login from "./components/auth/Login";
import Home from "./components/home/Home";
import Message from "./components/messages/Message";
import Watchdogs from "./components/watchdogs/Watchdogs";
import Perfomance from "./components/perfomance/Perfomance";
import ProcessScenario from "./components/process/ProcessScenario";
import Dashboard from "./components/dashboard/Dashboard";
import VisualSystem from "./components/visualSystem/VisualSystem";
import Value from "./components/value/Value";
import DeliverySite from "./components/deliverySite/DeliverySite";
import Order from "./components/order/Order";
import Interruption from "./components/interruption/Interruption";
import Errands from "./components/errands/Errands";
import Reports from "./components/reports/Reports";
import Administration from "./components/administration/Administration";
import Sql from "./components/sql/Sql";
import Map from "./components/map/Map";
import { baseUrl } from "./api/apiConstant";

const Reloadable = (props) => {
  const { location } = props;
  const [key, setKey] = useState(location.key);

  if (location.key !== key) {
    setTimeout(() => setKey(location.key), 0);
    return null;
  }
  return <>{props.children}</>;
};

function App() {
  let isLogin = store.getState().user.isLogin;
  useEffect(() => {
    axios.defaults.baseURL = baseUrl;
  }, [isLogin]);
  return (
    <Provider store={store}>
      <ToastProvider>
        <Router>
          <Fragment>
            <Switch>
              <Route path={"/login"} component={Login} />
              <Route path={"/home"} component={Home} />
              <Route path={"/message"} component={Message} />
              <Route path={"/watchdogs"} component={Watchdogs} />
              <Route path={"/perfomance"} component={Perfomance} />
              <Route path={"/dashboard"} component={Dashboard} />
              <Route path={"/visualSystem"} component={VisualSystem} />
              <Route path={"/value"} component={Value} />
              <Route path={"/deliverySite"} component={DeliverySite} />
              <Route path={"/order"} component={Order} />
              <Route path={"/interruption"} component={Interruption} />
              <Route path={"/errands"} component={Errands} />
              <Route path={"/reports"} component={Reports} />
              <Route path={"/administration"} component={Administration} />
              <Route path={"/sql"} component={Sql} />
              <Route path={"/map"} component={Map} />
              <Route path={"/processScenario"} component={ProcessScenario} />
              <Redirect to={"/login"} />
            </Switch>
          </Fragment>
        </Router>
      </ToastProvider>
    </Provider>
  );
}

// const Root = () => {
//   const [selIndex, setSelIndex] = useState(0);
//   return (
//     <Router>
//     <Container>
//       <Sidemenu
//         selIndex={selIndex}
//         onChangeIndex={(index) => setSelIndex(index)}
//       />
//       <Switch>
//         <Route exact path={"home"} component={Home} />
//         <Route exact path={"message"} component={Message} />
//       </Switch>
//     </Container>
//     </Router>
//   );
// };

export default App;
