import React, { useEffect, useState } from "react";

import { connect } from "react-redux";
import { useHistory } from "react-router-dom";
import { useToasts } from "react-toast-notifications";

import { loginUser } from "../../redux/action/userAction";
import logo from "../../img/logo.png";

const Login = (props) => {
  const history = useHistory();
  const { addToast } = useToasts();
  const [load, setLoad] = useState(false);
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");

  //API call methods
  const onCallLogin = () => {
    let params = {};
    params.user = username;
    params.password = password;

    setLoad(true);
    props
      .loginUser(params)
      .then(async (response) => {
        setLoad(false);
        console.log("Login response: ", response);
        const { status, message } = response;
        if (status) {
          history.push("/message");
          addToast(message, {
            appearance: "success",
            autoDismiss: true,
          });
        } else {
          addToast(message, {
            appearance: "warning",
            autoDismiss: true,
          });
        }
      })
      .catch((err) => {
        console.log("Login error: ", err);
        setLoad(false);
      });
  };

  //Validate methods
  const validateLogin = () => {
    if (username.length === 0) {
      addToast("Please enter username", {
        appearance: "warning",
        autoDismiss: true,
      });
      return false;
    }
    if (password.length === 0) {
      addToast("Please enter password", {
        appearance: "warning",
        autoDismiss: true,
      });
      return false;
    }
    return true;
  };

  //Button click events
  const onClickLogin = () => {
    if (validateLogin()) onCallLogin();
  };

  useEffect(() => {}, []);
  return (
    <div id="wrapper">
      <div id="loader-wrapper">
        <div className="loader-section section-left"></div>
        <div className="loader-section section-right"></div>
      </div>
      <div className="main-content block">
        <div className="container-custom">
          <div className="wrap-login">
            <div className="row mb-3">
              <div className="col-md-12 text-center">
                <h3 className="brand-text">MIISP</h3>
                <p className="brand-sub">
                  Integration Control Board Powered By
                </p>
                <img src={logo} className="image-fluid mb-2" alt="Logo" />
              </div>
            </div>
            <div className="row">
              <div className="col-md-12 text-center">
                <h4>Login to Admin</h4>
              </div>
            </div>
            <form>
              <div className="form-group">
                {" "}
                <i className="fa fa-user"></i>
                <input
                  type="text"
                  className="form-control"
                  placeholder="Username"
                  required="required"
                  value={username}
                  onChange={(e) => setUsername(e.target.value)}
                />
              </div>
              <div className="form-group">
                {" "}
                <i className="fa fa-lock"></i>
                <input
                  type="password"
                  className="form-control"
                  placeholder="Password"
                  required="required"
                  value={password}
                  onChange={(e) => setPassword(e.target.value)}
                />
              </div>
              <button
                onClick={() => onClickLogin()}
                type="button"
                className="btn btn-primary btn-block btn-sm"
              >
                {load ? (
                  <span
                    className="spinner-border spinner-border-sm"
                    role="status"
                    aria-hidden="true"
                  />
                ) : (
                  "Login"
                )}
              </button>
              {/* <div className="row">
                <div className="col-6 col-sm-6">
                  <a href="?#">
                    <p className="text-left pt-2 ml-1">Forgot password?</p>
                  </a>
                </div>
                <div className="col-6 col-sm-6">
                  <a href="?#">
                    <p className="text-right pt-2 mr-1">Sign Up Now</p>
                  </a>
                </div>
              </div> */}
            </form>
          </div>
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = (state) => {
  const { user, isLogin } = state.user;
  return {
    user,
    isLogin,
  };
};

export default connect(mapStateToProps, {
  loginUser,
})(Login);
