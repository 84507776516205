import { makeRequest } from "../../api/apiCall";
import { graphAcknowledge, graphAcknowledgeRow, graphMessages } from "../../api/apiConstant";

//Message Actions
export const getGraphMessages = (userData) => {
  return (dispatch, getState) => {
    try {
      return makeRequest(graphMessages, "get", userData)
        .then((response) => {
          console.log("Graph Data: " + response.data);
          return Promise.resolve({
            status: true,
            data: response.data || [],
            message: "",
          });
        })
        .catch((error) => {
          console.log("Error: ", error);
          return Promise.reject(error);
        });
    } catch (e) {
      return Promise.reject(e);
    }
  };
};

export const getGraphAcknowledge = (userData) => {
  return (dispatch, getState) => {
    try {
      return makeRequest(graphAcknowledge, "get", userData)
        .then((response) => {
          console.log("Graph Acknowledge Data: " + response.data);
          return Promise.resolve({
            status: true,
            data: response.data || [],
            message: "",
          });
        })
        .catch((error) => {
          console.log("Error: ", error);
          return Promise.reject(error);
        });
    } catch (e) {
      return Promise.reject(e);
    }
  };
};

export const getGraphAcknowledgeRow = (userData) => {
  return (dispatch, getState) => {
    try {
      return makeRequest(graphAcknowledgeRow, "get", userData)
        .then((response) => {
          console.log("Graph Acknowledge Row Data: " + response.data);
          return Promise.resolve({
            status: true,
            data: response.data || [],
            message: "",
          });
        })
        .catch((error) => {
          console.log("Error: ", error);
          return Promise.reject(error);
        });
    } catch (e) {
      return Promise.reject(e);
    }
  };
};
