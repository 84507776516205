import { makeRequest } from "../../api/apiCall";
import { processes } from "../../api/apiConstant";

//Message Actions
export const getGroupProcess = (userData) => {
  return (dispatch, getState) => {
    try {
      return makeRequest(processes, "get", userData)
        .then((response) => {
          console.log("Group Process Data: " + response.data);
          return Promise.resolve({
            status: true,
            data: response.data || [],
            message: "",
          });
        })
        .catch((error) => {
          console.log("Error: ", error);
          return Promise.reject(error);
        });
    } catch (e) {
      return Promise.reject(e);
    }
  };
};

export const getProcessByID = (userData) => {
  return (dispatch, getState) => {
    try {
      return makeRequest(processes + "/" + userData.id, "get", {})
        .then((response) => {
          console.log("Process by ID Data: " + response.data);
          return Promise.resolve({
            status: true,
            data: response.data || [],
            message: "",
          });
        })
        .catch((error) => {
          console.log("Error: ", error);
          return Promise.reject(error);
        });
    } catch (e) {
      return Promise.reject(e);
    }
  };
};
