export const ENVIRONMENT = {
  PROD: "PROD",
  DEV: "DEV",
};

export const baseUrl = "http://89.46.83.56:8081/v1/";

//Login
export const login = "authenticate";

//Administration
export const activeProcess = "admin/processes/active";
export const messageEndpoint = "metadata/message-endpoint-list";

//SQL
export const executeSql = "admin/execute-sql";

//Message
export const messages = "messages";
export const binaryInfo = "binary-info";
export const raw = "raw";
export const rows = "rows";
export const messageAcknowledgeRow = "messages/acknowledge/row";

//Values
export const values = "values";

//Acknowledge
export const messageAcknowledge = "messages/acknowledge";

//Process
export const processes = "processes";
export const history = "history";

//Watchdogs
export const watchdogs = "watchdogs";

//Message Perfomance
export const messagePerformance = "analytics/message-performance";

//Dropdown Metadata
export const messageAckAcknowledgeList =
  "metadata/message-ack-acknowledge-list";
export const resolutionList = "metadata/resolution-list";
export const searchTypeList = "metadata/search-type-list";
export const acknowledegeCategoryList = "metadata/acknowledege-category-list";
export const acknowledegeMessageTypeList =
  "metadata/acknowledege-messages-type-list";
export const messageValidationList = "metadata/message-validation-list";
export const messageCategories = "metadata/message-categories";
export const messageAcknowledgeList = "metadata/message-ack-acknowledge-list ";
export const systems = "metadata/systems";
export const users = "metadata/users";
export const messageTypeList = "metadata/message-type-list";
export const watchdogCategory = "analytics/process/watchdog-categories";
export const watchdogProcess = "analytics/process/watchdog-rules";

//Graph
export const graphMessages = "analytics/graph-data/messages";
export const graphAcknowledge = "analytics/graph-data/acknowledgements";
export const graphAcknowledgeRow = "analytics/graph-data/acknowledgements/rows";
export const systemToSystemMessage = "analytics/system-to-system/messages";
export const systemToSystemMessageTypes =
  "analytics/system-to-system/messages/message-types";
