import { LOGIN_DETAILS } from "../action/types";
import { appDefaultReducer } from "./defaultReducer";

const INITIAL_STATE = appDefaultReducer.user;

export default function foo(state = INITIAL_STATE, action) {
  switch (action.type) {
    case LOGIN_DETAILS: {
      return {
        ...state,
        user: action.payload.user,
        isLogin: action.payload.isLogin,
      };
    }
    default:
      return state;
  }
}
