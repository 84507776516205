import { makeRequest } from "../../api/apiCall";
import {
  systemToSystemMessage,
  systemToSystemMessageTypes,
} from "../../api/apiConstant";

//Values Actions
export const getSystemToSystemMessage = (userData) => {
  return (dispatch, getState) => {
    try {
      return makeRequest(systemToSystemMessage, "get", userData)
        .then((response) => {
          console.log("SystemToSystem Data: " + response.data);
          return Promise.resolve({
            status: true,
            data: response.data || [],
            message: "",
          });
        })
        .catch((error) => {
          console.log("Error: ", error);
          return Promise.reject(error);
        });
    } catch (e) {
      return Promise.reject(e);
    }
  };
};

export const getSystemToSystemMessageTypes = (userData) => {
  return (dispatch, getState) => {
    try {
      return makeRequest(systemToSystemMessageTypes, "get", userData)
        .then((response) => {
          console.log("SystemToSystem Data: " + response.data);
          return Promise.resolve({
            status: true,
            data: response.data || [],
            message: "",
          });
        })
        .catch((error) => {
          console.log("Error: ", error);
          return Promise.reject(error);
        });
    } catch (e) {
      return Promise.reject(e);
    }
  };
};
