import React from "react";

import ReactApexChart from "react-apexcharts";
import { AgGridReact } from "ag-grid-react";
import { connect } from "react-redux";
import moment from "moment";
import exportFromJSON from "export-from-json";

import iconXLS from "../../img/ic_xls.png";
import "@ag-grid-community/core/dist/styles/ag-grid.css";
import "@ag-grid-community/core/dist/styles/ag-theme-alpine.css";

import Sidemenu from "../drawer/Sidemenu";
import Container from "../layout/Container";
import Loader from "../layout/Loader";
import MessageDetail from "./subComponent/MessageDetail";

import {
  getGraphMessages,
  getGraphAcknowledge,
  getGraphAcknowledgeRow,
} from "../../redux/action/graphAction";
import {
  getMessages,
  getMessagesRows,
  getMessageRaw,
  getMessageBinary,
  getMessageAcknowledgeRows,
} from "../../redux/action/messageAction";
import {
  getAcknowledgeMessage,
  getAcknowledgeMessageTypeList,
} from "../../redux/action/acknowledgeAction";
import {
  getGroupProcess,
  getProcessByID,
} from "../../redux/action/processAction";
import {
  getSystems,
  getMessageValidation,
  getMessageTypeList,
  getSearchTypeList,
  getMessageAcknowledgeList,
  getMessageCategories,
  getResolutions,
  getWatchdogCategory,
} from "../../redux/action/metadataAction";
import { getWatchdogDataList } from "../../redux/action/watchdogAction";

const tabs = [
  { title: "Search Messages", id: "#tab_1" },
  { title: "Search Acknowledges", id: "#tab_2" },
  { title: "Messages Graph", id: "#tab_3" },
  { title: "Acknowledgement Graph", id: "#tab_4" },
  { title: "Acknowledgement Rows Graph", id: "#tab_5" },
  //{ title: "Messages Time Line", id: "#tab_6" },
];
const miniTab = [
  { title: "Workflow", id: "#min_tab_1" },
  { title: "Readings", id: "#min_tab_2" },
  { title: "Components", id: "#min_tab_3" },
  { title: "Materials", id: "#min_tab_4" },
  { title: "Services", id: "#min_tab_5" },
  { title: "Order Responses", id: "#min_tab_6" },
];

class Message extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      /** Common */
      load: false,
      tabIndex: 0,
      miniTabIndex: 0,

      /** Metadata */
      systems: [],
      acknowledge: [],
      messageTypes: [],
      acknowledgeMessageTypes: [],
      searchType: [],
      watchdogCategory: [],
      watchdogProcess: [],
      messageCategory: [],
      messageValidation: [],
      times: [],
      groupProcess: [],
      process: [],

      /** Messages */
      isLoadMessage: false,
      isLoadMessageRow: false,
      isLoadMessageDetail: false,
      isMessageModal: false,
      selSenderIndex: null,
      selReceiverIndex: null,
      selValidationIndex: null,
      selMessageTypeIndex: null,
      selAcknowledgeIndex: null,
      selSearchTypeIndex: null,
      selWatchdogCategoryIndex: null,
      selWatchdogProcessIndex: null,
      selGroupProcessIndex: null,
      selProcessIndex: null,
      selMessageID: "",
      txtFrom: "",
      txtTo: "",
      txtDeliverysiteCode: "",
      txtMessageId: "",
      txtCorreltationId: "",
      txtOrderId: "",
      txtParentOrderId: "",
      txtOrderType: "",
      messages: [],
      messageBinary: {},
      messageRaw: {},
      messagesRow: [],
      messagesRowDetail: [],
      messagesRowValueDetail: [],

      /** Search Acknowledge */
      isLoadMessageAcknowledge: false,
      isLoadMessageAcknowledgeRow: false,
      txtSAFrom: "",
      txtSATo: "",
      txtSADeliverysiteCode: "",
      txtSAAcknowledgeId: "",
      txtSACorreltationId: "",
      txtSAAckCode: "",
      txtSAAckText: "",
      selSASenderIndex: null,
      selSAReceiverIndex: null,
      selSAValidationIndex: null,
      selSAMessageTypeIndex: null,
      selSAAcknowledgeIndex: null,
      messageAcknowledge: [],
      messageAcknowledgeRow: [],

      /** Message Graph */
      isLoadMessageGraph: false,
      isPie: false,
      isColumn: true,
      txtMDeliverysiteCode: "",
      txtMFrom: "",
      txtMTo: "",
      txtMFromHour: "",
      txtMToHour: "",
      txtMFromMin: "",
      txtMToMin: "",
      selMSenderIndex: null,
      selMReceiverIndex: null,
      selMValidationIndex: null,
      selMMessageTypeIndex: null,
      selMAcknowledgeIndex: null,
      selMSearchTypeIndex: null,
      selMMessageCategoryIndex: null,
      selMTimeIndex: null,
      graphPieMessage: [],
      graphMessage: [],
      graphMessageCategory: [],

      /** Acknowledge Graph */
      isLoadAcknowledgeGraph: false,
      txtADeliverysiteCode: "",
      txtAAckCode: "",
      txtAAckText: "",
      txtAFrom: "",
      txtATo: "",
      txtAFromHour: "",
      txtAToHour: "",
      txtAFromMin: "",
      txtAToMin: "",
      selASenderIndex: null,
      selAReceiverIndex: null,
      selAMessageTypeIndex: null,
      selAMessageCategoryIndex: null,
      selAAcknowledgeIndex: null,
      selATimeIndex: null,
      graphAcknowledge: [],
      graphAcknowledgeCategory: [],

      /** Acknowledge Row Graph */
      isLoadAcknowledgeRowGraph: false,
      txtARDeliverysiteCode: "",
      txtARAckCode: "",
      txtARAckText: "",
      txtARFrom: "",
      txtARTo: "",
      txtARFromHour: "",
      txtARToHour: "",
      txtARFromMin: "",
      txtARToMin: "",
      selARSenderIndex: null,
      selARReceiverIndex: null,
      selARMessageTypeIndex: null,
      selARMessageCategoryIndex: null,
      selARAcknowledgeIndex: null,
      selARTimeIndex: null,
      graphAcknowledgeRow: [],
      graphAcknowledgeRowCategory: [],

      series: [44, 55, 50],
      options: [],
    };
  }

  componentDidMount() {
    this.onCallGetSystems();
    this.onCallMessageType();
    this.onCallSearchType();
    this.onCallAcknowledgeList();
    this.onCallMessageValidation();
    this.onCallMessageCategories();
    this.onCallResolutions();
    this.onCallGetGroupProcess();
    this.onCallAcknowledgeMessageTypeList();
  }

  //Button Click Events
  onClickSearch = () => {
    this.onCallGetMessages();
  };

  //API Call Methods
  onCallGetMessageAcknowledge = () => {
    const {
      txtSAFrom,
      txtSATo,
      txtSADeliverysiteCode,
      txtSAAckCode,
      txtSAAckText,
      txtSAAcknowledgeId,
      txtSACorreltationId,
      selSAAcknowledgeIndex,
      selSAMessageTypeIndex,
      selSAReceiverIndex,
      selSASenderIndex,
      selSAValidationIndex,
    } = this.state;

    let params = {};
    if (txtSAFrom) params.fromDate = moment(txtSAFrom).format("YYYY-MM-DD");
    if (txtSATo) params.toDate = moment(txtSATo).format("YYYY-MM-DD");
    if (txtSADeliverysiteCode)
      params.deliverysiteEanCode = txtSADeliverysiteCode;
    if (txtSACorreltationId) params.correlationId = txtSACorreltationId;
    if (selSASenderIndex) params.sendingSystemId = selSASenderIndex;
    if (selSAReceiverIndex) params.receivingSystemId = selSAReceiverIndex;
    if (selSAMessageTypeIndex) params.messageType = selSAMessageTypeIndex;
    if (selSAValidationIndex) params.messageValidation = selSAValidationIndex;
    if (selSAAcknowledgeIndex) params.searchType = selSAAcknowledgeIndex;
    if (txtSAAcknowledgeId) params.messageId = txtSAAcknowledgeId;
    if (txtSAAckCode) params.AckCode = txtSAAckCode;
    if (txtSAAckText) params.AckText = txtSAAckText;

    this.setState({ isLoadMessageAcknowledge: true });
    this.props
      .getAcknowledgeMessage(params)
      .then(async (response) => {
        this.setState({ isLoadMessageAcknowledge: false });
        console.log("Message Acknowledge response: ", response);
        this.manageMessageAcknowledge(response.data || []);
      })
      .catch((err) => {
        this.setState({ isLoadMessageAcknowledge: false });
        console.log("Message Acknowledge error: ", err);
      });
  };
  onCallGetGraphAcknowledgeRow = () => {
    const {
      txtARFrom,
      txtARTo,
      txtARFromHour,
      txtARToHour,
      txtARFromMin,
      txtARToMin,
      txtARDeliverysiteCode,
      txtARAckCode,
      txtARAckText,
      selARSenderIndex,
      selARReceiverIndex,
      selARAcknowledgeIndex,
      selARTimeIndex,
      selARMessageTypeIndex,
      selARMessageCategoryIndex,
    } = this.state;

    let params = {};
    if (txtARFrom)
      params.fromTimestamp = moment(txtARFrom).format("YYYY-MM-DD HH:mm:SS");
    if (txtARTo)
      params.toTimestamp = moment(txtARTo).format("YYYY-MM-DD HH:mm:SS");
    if (txtARDeliverysiteCode)
      params.deliverysiteEanCode = txtARDeliverysiteCode;
    if (txtARAckCode) params.acknowledgeCode = txtARAckCode;
    if (txtARAckText) params.acknowledgeText = txtARAckText;
    if (selARSenderIndex) params.sendingSystemId = selARSenderIndex;
    if (selARReceiverIndex) params.receivingSystemId = selARReceiverIndex;
    if (selARMessageTypeIndex) params.messageType = selARMessageTypeIndex;
    if (selARTimeIndex && selARTimeIndex === "1") params.resolution = "Y";
    if (selARTimeIndex && selARTimeIndex === "2") params.resolution = "M";
    if (selARTimeIndex && selARTimeIndex === "3") params.resolution = "D";
    if (selARTimeIndex && selARTimeIndex === "4") params.resolution = "H";
    if (selARTimeIndex && selARTimeIndex === "5") params.resolution = "MI";
    //if (selARTimeIndex && selARTimeIndex === "3") params.resolution = "W";

    this.setState({ isLoadAcknowledgeRowGraph: true });
    this.props
      .getGraphAcknowledgeRow(params)
      .then(async (response) => {
        this.setState({ isLoadAcknowledgeRowGraph: false });
        console.log("Graph Acknowledge Row response: ", response);
        this.manageGraphAcknowledgeRow(response.data || []);
      })
      .catch((err) => {
        this.setState({ isLoadAcknowledgeRowGraph: false });
        console.log("Graph Acknowledge Row error: ", err);
      });
  };
  onCallGetGraphAcknowledge = () => {
    const {
      txtAFrom,
      txtATo,
      txtAFromHour,
      txtAToHour,
      txtAFromMin,
      txtAToMin,
      txtADeliverysiteCode,
      txtAAckCode,
      txtAAckText,
      selASenderIndex,
      selAReceiverIndex,
      selAAcknowledgeIndex,
      selATimeIndex,
      selAMessageTypeIndex,
      selAMessageCategoryIndex,
    } = this.state;

    let params = {};
    if (txtAFrom)
      params.fromTimestamp = moment(txtAFrom).format("YYYY-MM-DD HH:mm:SS");
    if (txtATo)
      params.toTimestamp = moment(txtATo).format("YYYY-MM-DD HH:mm:SS");
    if (txtADeliverysiteCode) params.deliverysiteEanCode = txtADeliverysiteCode;
    if (txtAAckCode) params.acknowledgeCode = txtAAckCode;
    if (txtAAckText) params.acknowledgeText = txtAAckText;
    if (selASenderIndex) params.sendingSystemId = selASenderIndex;
    if (selAReceiverIndex) params.receivingSystemId = selAReceiverIndex;
    if (selAMessageTypeIndex) params.messageType = selAMessageTypeIndex;
    if (selATimeIndex && selATimeIndex === "1") params.resolution = "Y";
    if (selATimeIndex && selATimeIndex === "2") params.resolution = "M";
    if (selATimeIndex && selATimeIndex === "3") params.resolution = "D";
    if (selATimeIndex && selATimeIndex === "4") params.resolution = "H";
    if (selATimeIndex && selATimeIndex === "5") params.resolution = "MI";
    //if (selATimeIndex && selATimeIndex === "3") params.resolution = "W";

    //console.log("params: ", params);

    this.setState({ isLoadAcknowledgeGraph: true });
    this.props
      .getGraphAcknowledge(params)
      .then(async (response) => {
        this.setState({ isLoadAcknowledgeGraph: false });
        console.log("Graph Acknowledge response: ", response);
        this.manageGraphAcknowledge(response.data || []);
      })
      .catch((err) => {
        this.setState({ isLoadAcknowledgeGraph: false });
        console.log("Graph Acknowledge error: ", err);
      });
  };
  onCallGetGraphMessages = () => {
    const {
      txtMFrom,
      txtMTo,
      txtMFromHour,
      txtMToHour,
      txtMFromMin,
      txtMToMin,
      txtMDeliverysiteCode,
      selMSenderIndex,
      selMAcknowledgeIndex,
      selMReceiverIndex,
      selMTimeIndex,
      selMMessageTypeIndex,
      selMSearchTypeIndex,
      selMValidationIndex,
      selMMessageCategoryIndex,
    } = this.state;

    let params = {};
    let fromDate = moment(txtMFrom).format("YYYY-MM-DD+");
    let fromTime = `${txtMFromHour || "00"}:${txtMFromMin || "00"}:00`;
    if (txtMFrom) params.fromTimestamp = `${fromDate}${fromTime}`;

    let toDate = moment(txtMTo).format("YYYY-MM-DD+");
    let toTime = `${txtMToHour || "00"}:${txtMToMin || "00"}:00`;
    if (txtMTo) params.toTimestamp = `${toDate}${toTime}`;

    if (selMAcknowledgeIndex) params.acknowledge = selMAcknowledgeIndex;
    if (txtMDeliverysiteCode) params.deliverysiteEanCode = txtMDeliverysiteCode;
    if (selMSenderIndex) params.sendingSystemId = selMSenderIndex;
    if (selMReceiverIndex) params.receivingSystemId = selMReceiverIndex;
    if (selMMessageTypeIndex) params.messageType = selMMessageTypeIndex;
    if (selMSearchTypeIndex) params.searchType = selMSearchTypeIndex;
    if (selMValidationIndex) params.messageValidation = selMValidationIndex;
    if (selMTimeIndex && selMTimeIndex === "1") params.resolution = "Y";
    if (selMTimeIndex && selMTimeIndex === "2") params.resolution = "M";
    if (selMTimeIndex && selMTimeIndex === "3") params.resolution = "D";
    if (selMTimeIndex && selMTimeIndex === "4") params.resolution = "H";
    if (selMTimeIndex && selMTimeIndex === "5") params.resolution = "MI";
    // if (selMTimeIndex && selMTimeIndex === "3") params.resolution = "W";

    console.log("params: ", params);

    this.setState({ isLoadMessageGraph: true });
    this.props
      .getGraphMessages(params)
      .then(async (response) => {
        this.setState({ isLoadMessageGraph: false });
        console.log("Graph response: ", response);
        this.manageGraphMessages(response.data || []);
      })
      .catch((err) => {
        this.setState({ isLoadMessageGraph: false });
        console.log("Graph error: ", err);
      });
  };
  onCallResolutions = () => {
    let params = {};
    this.props
      .getResolutions(params)
      .then(async (response) => {
        console.log("Resolutions response: ", response);
        this.setState({ times: response.data || [] });
      })
      .catch((err) => {
        console.log("Resolutions error: ", err);
      });
  };
  onCallMessageCategories = () => {
    let params = {};
    this.props
      .getMessageCategories(params)
      .then(async (response) => {
        console.log("Categories response: ", response);
        this.setState({ messageCategory: response.data || [] });
      })
      .catch((err) => {
        console.log("Categories error: ", err);
      });
  };
  onCallSearchType = () => {
    let params = {};
    this.props
      .getSearchTypeList(params)
      .then(async (response) => {
        console.log("Search response: ", response);
        this.setState({ searchType: response.data || [] });
      })
      .catch((err) => {
        console.log("Search error: ", err);
      });
  };
  onCallAcknowledgeList = () => {
    let params = {};
    this.props
      .getMessageAcknowledgeList(params)
      .then(async (response) => {
        console.log("Acknowledge response: ", response);
        this.setState({ acknowledge: response.data || [] });
      })
      .catch((err) => {
        console.log("Acknowledge error: ", err);
      });
  };
  onCallMessageType = () => {
    let params = {};
    this.props
      .getMessageTypeList(params)
      .then(async (response) => {
        console.log("Types response: ", response);
        this.setState({ messageTypes: response.data || [] });
      })
      .catch((err) => {
        console.log("Types error: ", err);
      });
  };
  onCallMessageValidation = () => {
    let params = {};
    this.props
      .getMessageValidation(params)
      .then(async (response) => {
        console.log("Validation response: ", response);
        this.setState({ messageValidation: response.data || [] });
      })
      .catch((err) => {
        console.log("Validation error: ", err);
      });
  };
  onCallGetSystems = () => {
    let params = {};
    this.props
      .getSystems(params)
      .then(async (response) => {
        console.log("Systems response: ", response);
        this.setState({ systems: response.data || [] });
      })
      .catch((err) => {
        console.log("Systems error: ", err);
      });
  };
  onCallGetGroupProcess = () => {
    let params = {};
    this.props
      .getWatchdogDataList(params)
      .then(async (response) => {
        console.log("Group Process response: ", response);
        this.setState({ groupProcess: response.data || [] });
      })
      .catch((err) => {
        console.log("Group Process error: ", err);
      });
  };
  onCallGetProcessByID = (id) => {
    let params = {};
    params.id = id;
    this.props
      .getProcessByID(params)
      .then(async (response) => {
        console.log("Process response: ", response);
        this.setState({ process: response.data || [] });
      })
      .catch((err) => {
        console.log("Process error: ", err);
      });
  };
  onCallGetMessageBinaryInfo = (id) => {
    let params = {};
    params.id = id;
    this.setState({ isLoadMessageDetail: true });
    this.props
      .getMessageBinary(params)
      .then(async (response) => {
        this.setState({ isLoadMessageDetail: false });
        console.log("Message Binary response: ", response);
        this.setState({ messageBinary: response.data || [] });
      })
      .catch((err) => {
        this.setState({ isLoadMessageDetail: false });
        console.log("Message Binary error: ", err);
      });
  };
  onCallGetMessageRaw = (id) => {
    let params = {};
    params.id = id;
    this.props
      .getMessageRaw(params)
      .then(async (response) => {
        console.log("Message Raw response: ", response);
        this.setState({
          messageRaw: response.data || "",
          isMessageModal: true,
        });
      })
      .catch((err) => {
        console.log("Message Raw error: ", err);
      });
  };
  onCallAcknowledgeMessageTypeList = () => {
    let params = {};
    this.props
      .getAcknowledgeMessageTypeList(params)
      .then(async (response) => {
        console.log("Acknowledge Message Type response: ", response);
        this.setState({ acknowledgeMessageTypes: response.data || [] });
      })
      .catch((err) => {
        console.log("Acknowledge Message Type error: ", err);
      });
  };
  onCallGetMessages = () => {
    const {
      txtFrom,
      txtTo,
      txtCorreltationId,
      txtMessageId,
      txtDeliverysiteCode,
      txtParentOrderId,
      txtOrderId,
      txtOrderType,
      selSenderIndex,
      selReceiverIndex,
      selValidationIndex,
      selMessageTypeIndex,
      selAcknowledgeIndex,
      selSearchTypeIndex,
    } = this.state;

    let params = {};
    if (txtFrom)
      params.fromDate = moment(txtFrom).format("YYYY-MM-DD+00:00:00");
    if (txtTo) params.toDate = moment(txtTo).format("YYYY-MM-DD+00:00:00");
    if (txtDeliverysiteCode) params.deliverysiteEanCode = txtDeliverysiteCode;
    if (txtOrderId) params.orderId = txtOrderId;
    if (txtMessageId) params.messageId = txtMessageId;
    if (txtParentOrderId) params.parentOrderId = txtParentOrderId;
    if (txtCorreltationId) params.correlationId = txtCorreltationId;
    if (txtOrderType) params.orderType = txtOrderType;
    if (selSenderIndex) params.sendingSystemId = selSenderIndex;
    if (selReceiverIndex) params.receivingSystemId = selReceiverIndex;
    if (selValidationIndex) params.messageValidation = selValidationIndex;
    if (selMessageTypeIndex) params.messageType = selMessageTypeIndex;
    if (selAcknowledgeIndex) params.acknowledge = selAcknowledgeIndex;
    if (selSearchTypeIndex) params.searchType = selSearchTypeIndex;

    console.log("params: ", params);

    this.setState({ isLoadMessage: true });
    this.props
      .getMessages(params)
      .then(async (response) => {
        this.setState({ isLoadMessage: false });
        console.log("Message response: ", response);
        this.manageMessages(response.data || []);
      })
      .catch((err) => {
        console.log("Message error: ", err);
        this.setState({ isLoadMessage: false });
      });
  };
  onCallGetAcknowledgeMessageRows = () => {
    const {
      txtSAFrom,
      txtSATo,
      txtSADeliverysiteCode,
      txtSAAckCode,
      txtSAAckText,
      txtSAAcknowledgeId,
      txtSACorreltationId,
      selSAAcknowledgeIndex,
      selSAMessageTypeIndex,
      selSAReceiverIndex,
      selSASenderIndex,
      selSAValidationIndex,
    } = this.state;

    let params = {};
    if (txtSAFrom) params.fromDate = moment(txtSAFrom).format("YYYY-MM-DD");
    if (txtSATo) params.toDate = moment(txtSATo).format("YYYY-MM-DD");
    if (txtSADeliverysiteCode)
      params.deliverysiteEanCode = txtSADeliverysiteCode;
    if (txtSACorreltationId) params.correlationId = txtSACorreltationId;
    if (selSASenderIndex) params.sendingSystemId = selSASenderIndex;
    if (selSAReceiverIndex) params.receivingSystemId = selSAReceiverIndex;
    if (selSAMessageTypeIndex) params.messageType = selSAMessageTypeIndex;
    if (selSAValidationIndex) params.messageValidation = selSAValidationIndex;
    if (selSAAcknowledgeIndex) params.searchType = selSAAcknowledgeIndex;
    if (txtSAAcknowledgeId) params.messageId = txtSAAcknowledgeId;
    if (txtSAAckCode) params.AckCode = txtSAAckCode;
    if (txtSAAckText) params.AckText = txtSAAckText;

    // this.setState({ isLoadMessageAcknowledge: true });
    this.props
      .getMessageAcknowledgeRows(params)
      .then(async (response) => {
        // this.setState({ isLoadMessageAcknowledge: false });
        console.log("Acknowledge message row response: ", response);
        this.manageMessageAcknowledgeRows(response.data || []);
      })
      .catch((err) => {
        console.log("Acknowledge message row error: ", err);
        // this.setState({ isLoadMessageAcknowledge: false });
      });
  };
  onCallGetMessagesRows = (messageID) => {
    let params = {};
    params.id = messageID;
    this.setState({ isLoadMessageRow: true });
    this.props
      .getMessagesRows(params)
      .then(async (response) => {
        this.setState({ isLoadMessageRow: false });
        console.log("Message row response: ", response);
        this.setState({ selMessageID: messageID }, () => {
          this.manageMessageRow(response.data || []);
        });
      })
      .catch((err) => {
        console.log("Message row error: ", err);
        this.setState({ isLoadMessageRow: false });
      });
  };

  //Data Methods
  manageMessageAcknowledgeRows = (data) => {
    console.log("messageAcknowledgeRow: ", data);
    let arrMessageAcknowledgeRow = [];
    for (const obj of data) {
      let dict = {};
      dict._ = "🔍";
      dict.__ = "🔍";
      dict.acknowledgeId = obj.messageId;
      dict.correltaionId = obj.correlationId;
      dict.deliverysiteId = obj.deliverySiteEanCode;
      dict.orderId = obj.orderId;
      dict.responseCode = obj.ackResponseCode;
      dict.responseText = obj.ackResponseText;
      dict.recordPosition = "";
      dict.position = "";
      dict.messageReceiverSystem = obj.receiverSystemName;
      dict.messageSenderSystem = obj.senderSystemName;
      dict.messageType = obj.messageType;
      dict.messageCreated = obj.messageCreatedDateTime;
      dict.messageReceived = obj.messageReceivedDateTime;
      arrMessageAcknowledgeRow.push(dict);
    }
    this.setState({ messageAcknowledgeRow: arrMessageAcknowledgeRow });
  };
  manageMessageAcknowledge = (data) => {
    let arrMessageAcknowledge = [];
    for (const obj of data) {
      let dict = {};
      dict._ = "🔍";
      dict.__ = "🔍";
      dict.acknowledgeId = obj.messageId;
      dict.correltaionId = obj.correlationId;
      dict.dispatchId = obj.dispatchId;
      dict.dispatchId = obj.dispatchId;
      dict.messageCreated = obj.messageCreatedDateTime
        ? moment(obj.messageCreatedDateTime).format("YYYY-MM-DD hh:mm:ss")
        : "";
      dict.messageReceived = obj.messageReceivedDateTime
        ? moment(obj.messageReceivedDateTime).format("YYYY-MM-DD hh:mm:ss")
        : "";
      dict.messageType = obj.messageType;
      dict.messageSenderSystem = obj.senderSystemName;
      dict.messageReceiverSystem = obj.receiverSystemName;
      dict.recordCount = obj.recordCount;
      dict.internalRectDate = obj.rectDate
        ? moment(obj.rectDate).format("YYYY-MM-DD hh:mm:ss")
        : "";
      arrMessageAcknowledge.push(dict);
    }
    this.setState({ messageAcknowledge: arrMessageAcknowledge });
  };
  manageGraphMessages = (data) => {
    let arrGraphMessage = [];
    let arrAcked = [];
    let arrNotAcked = [];
    let arrNacked = [];
    let arrGraphMessageCategory = [];
    let arrGraphPieMessage = [];
    let intAcked = 0;
    let intNotAcked = 0;
    let intNacked = 0;

    for (const obj of data) {
      console.log("obj: ", obj);
      arrAcked.push(obj.yaxis_IVALUE || 0);
      arrNotAcked.push(obj.yaxis_IVALUE2 || 0);
      arrNacked.push(obj.yaxis_IVALUE3 || 0);
      let date = moment(obj.xaxis_DVALUE).format("YYYY-MM-DD HH:mm:ss");
      let isExist = arrGraphMessageCategory.findIndex((el) => el === date);
      if (isExist === -1) arrGraphMessageCategory.push(date);
      intAcked += obj.yaxis_IVALUE;
      intNacked += obj.yaxis_IVALUE3;
      intNotAcked += obj.yaxis_IVALUE2;
    }

    let notAcked = {
      name: "Not Acked",
      data: arrNotAcked,
    };
    let acked = {
      name: "Acked",
      data: arrAcked,
    };
    let nacked = {
      name: "Nacked",
      data: arrNacked,
    };

    arrGraphMessage.push(notAcked);
    arrGraphMessage.push(acked);
    arrGraphMessage.push(nacked);
    arrGraphPieMessage.push(intNotAcked);
    arrGraphPieMessage.push(intAcked);
    arrGraphPieMessage.push(intNacked);

    console.log("arrGraphMessage: ", arrGraphMessage);
    console.log("arrGraphPieMessage: ", arrGraphPieMessage);
    console.log("arrGraphMessageCategory: ", arrGraphMessageCategory);
    if (intAcked !== 0 || intNacked !== 0 || intNotAcked !== 0)
      this.setState({
        graphMessage: arrGraphMessage,
        graphMessageCategory: arrGraphMessageCategory,
        graphPieMessage: arrGraphPieMessage,
      });
  };
  manageGraphAcknowledge = (data) => {
    let arrGraphAcknowledge = [];
    let arrAcked = [];
    let arrNotAcked = [];
    let arrNacked = [];
    let arrGraphAcknowledgeCategory = [];
    let intAcked = 0;
    let intNotAcked = 0;
    let intNacked = 0;

    for (const obj of data) {
      console.log("obj: ", obj);
      arrAcked.push(obj.yaxis_IVALUE || 0);
      arrNotAcked.push(obj.yaxis_IVALUE2 || 0);
      arrNacked.push(obj.yaxis_IVALUE3 || 0);
      arrGraphAcknowledgeCategory.push(
        moment(obj.xaxis_DVALUE).format("YYYY-MM-DD")
      );
      intAcked += obj.yaxis_IVALUE;
      intNotAcked += obj.yaxis_IVALUE2;
      intNacked += obj.yaxis_IVALUE3;
    }

    let notAcked = {
      name: "Not Acked",
      data: arrNotAcked,
    };
    let acked = {
      name: "Acked",
      data: arrAcked,
    };
    let nacked = {
      name: "Nacked",
      data: arrNacked,
    };

    arrGraphAcknowledge.push(notAcked);
    arrGraphAcknowledge.push(acked);
    arrGraphAcknowledge.push(nacked);

    this.setState({
      graphAcknowledge: arrGraphAcknowledge,
      graphAcknowledgeCategory: arrGraphAcknowledgeCategory,
    });
  };
  manageGraphAcknowledgeRow = (data) => {
    let arrGraphAcknowledgeRow = [];
    let arrAcked = [];
    let arrNotAcked = [];
    let arrNacked = [];
    let arrGraphAcknowledgeRowCategory = [];
    let intAcked = 0;
    let intNotAcked = 0;
    let intNacked = 0;

    for (const obj of data) {
      console.log("obj: ", obj);
      arrAcked.push(obj.yaxis_IVALUE || 0);
      arrNotAcked.push(obj.yaxis_IVALUE2 || 0);
      arrNacked.push(obj.yaxis_IVALUE3 || 0);
      arrGraphAcknowledgeRowCategory.push(
        moment(obj.xaxis_DVALUE).format("YYYY-MM-DD")
      );
      intAcked += obj.yaxis_IVALUE;
      intNotAcked += obj.yaxis_IVALUE2;
      intNacked += obj.yaxis_IVALUE3;
    }

    let notAcked = {
      name: "Not Acked",
      data: arrNotAcked,
    };
    let acked = {
      name: "Acked",
      data: arrAcked,
    };
    let nacked = {
      name: "Nacked",
      data: arrNacked,
    };

    arrGraphAcknowledgeRow.push(notAcked);
    arrGraphAcknowledgeRow.push(acked);
    arrGraphAcknowledgeRow.push(nacked);

    this.setState({
      graphAcknowledgeRow: arrGraphAcknowledgeRow,
      graphAcknowledgeRowCategory: arrGraphAcknowledgeRowCategory,
    });
  };
  manageMessages = (data) => {
    let arrMessage = [];
    for (const obj of data) {
      let dict = {};
      dict.messageId = obj.messageId;
      dict.correltaionId = obj.correlationId;
      dict.acknowledgeId = obj.ackMessgeId;
      dict.messageCreated = obj.messageCreatedDateTime;
      dict.messageReceived = obj.messageReceivedDateTime;
      dict.messageType = obj.messageType;
      dict.senderSystem = obj.senderSystemName;
      dict.receiverSystem = obj.receiverSystemName;
      dict.internalRectDate = obj.rectDate;
      dict._ = "🔍";
      dict.__ = "🔍";
      let xmlValidVal = "";
      if (obj.xmlValid === "0") xmlValidVal = "red";
      if (obj.xmlValid === "1") xmlValidVal = "green";
      dict.xmlValid = xmlValidVal;
      let ackResponseCodeVal = "";
      if (!obj.ackResponseCode || obj.ackResponseCode === null)
        ackResponseCodeVal = "orange";
      if (
        obj.ackResponseCode &&
        obj.ackResponseCode.length > 0 &&
        obj.ackResponseCode !== "0"
      )
        ackResponseCodeVal = "red";
      if (obj.ackResponseCode === "0") ackResponseCodeVal = "green";
      dict.ackResponseCode = ackResponseCodeVal;
      arrMessage.push(dict);
    }
    this.setState({ messages: arrMessage });
  };
  manageMessageRow = (data) => {
    let arrMessageRow = [];
    for (const obj of data) {
      let dict = {};
      dict.messageId = this.state.selMessageID;
      dict.deliverysiteId = obj.deliverySiteEanCode;
      dict.parentOrderId = obj.parentOrderId;
      dict.orderId = obj.orderId;
      dict.orderTypeId = obj.orderTypeId;
      dict.orderTypeName = obj.orderTypeName;
      dict.orderStatusId = obj.orderStatusId;
      dict.orderStatus = obj.orderStatusText;
      dict.parentOrder = "";
      dict.parentOrderType = "";
      dict.deviceId = "";
      dict.settlementType = "";
      dict.readingInterval = "";
      dict.customer = "";
      dict.network = obj.network;
      dict.concessionArea = "";
      dict.meterCapacity = "";
      dict.meterCapacityDecimal = "";
      dict.startTime = obj.startDateTime;
      dict.endTime = obj.endDateTime;
      dict.marketActorId = obj.marketActorId;
      dict.balanceActorId = obj.balanceActorId;
      dict.distributionActorId = "";
      dict.interruptionStartTime = obj.interruptionStartDateTime;
      dict.interruptionEndTime = obj.interruptionEndDateTime;
      dict.rowDetail =
        obj.guiMessageRowActionObjectCollection.guiMessageRowActionVector;
      dict.rowValueDetail =
        obj.guiMessageRowValueObjectCollection.messageRowValueVector;
      arrMessageRow.push(dict);
    }
    this.setState({ messagesRow: arrMessageRow });
  };
  manageMessageRowDetail = (data) => {
    console.log("messagesRowDetail: ", data);
    let arrMessageRowDetail = [];
    for (const obj of data) {
      let dict = {};
      dict.actionId = obj.actionId;
      dict.action = obj.actionDesc;
      dict.mandatory = obj.actionMandatoryToSet;
      dict.target = obj.actionTargetDateTime || "";
      dict.performed = obj.actionDateTime;
      dict.user = obj.actionUser;
      dict.responseCode = obj.actionRespondCode;
      dict.comment = obj.actionComment;
      arrMessageRowDetail.push(dict);
    }
    this.setState({ messagesRowDetail: arrMessageRowDetail });
  };
  manageMessageRowValueDetail = (data) => {
    console.log("messagesRowValueDetail: ", data);
    let arrMessageRowValueDetail = [];
    for (const obj of data) {
      let dict = {};
      dict.readingTime = obj.readingDateTime;
      dict.reading = obj.reading;
      dict.internalStatusId = obj.internalMessageId;
      dict.svkCode = obj.svkCode;
      dict.externalStatusId = obj.extReadingStatus;
      dict.readingTypeId = obj.readingTypeId;
      dict.readingReasonId = obj.readingReasonId;
      dict.consumptionStartTime = obj.startConsumptionDateTime;
      dict.consumption = obj.consumption;
      dict.consumptionStatusId = obj.consumptionStatusId;
      dict.consumptionTypeId = obj.consumptionTypeId;
      arrMessageRowValueDetail.push(dict);
    }
    this.setState({ messagesRowValueDetail: arrMessageRowValueDetail });
  };

  //Export Methods
  exportToXLS = (data, fileName) => {
    const exportType = "xls";
    exportFromJSON({ data, fileName, exportType });
  };

  //Render Methods
  render() {
    const arrMessageValidation = Object.assign(
      [],
      this.state.messageValidation
    );
    const arrSystems = Object.assign([], this.state.systems);
    const arrAcknowledge = Object.assign([], this.state.acknowledge);
    const arrSearchTypes = Object.assign([], this.state.searchType);
    const arrMessageTypes = Object.assign([], this.state.messageTypes);
    const arrAcknowledgeMessageTypes = Object.assign(
      [],
      this.state.acknowledgeMessageTypes
    );
    const arrMessageCategory = Object.assign([], this.state.messageCategory);
    const arrGroupProcess = Object.assign([], this.state.groupProcess);
    const arrProcess = Object.assign([], this.state.process);
    const arrTimes = Object.assign([], this.state.times);

    const arrMessage = Object.assign([], this.state.messages);
    const arrMessageRow = Object.assign([], this.state.messagesRow);
    const arrMessageRowDetail = Object.assign([], this.state.messagesRowDetail);
    const arrMessageRowValueDetail = Object.assign(
      [],
      this.state.messagesRowValueDetail
    );

    const arrMessageAcknowledge = Object.assign(
      [],
      this.state.messageAcknowledge
    );
    const arrMessageAcknowledgeRow = Object.assign(
      [],
      this.state.messageAcknowledgeRow
    );

    const arrGraphPieMessage = Object.assign([], this.state.graphPieMessage);
    const arrGraphMessage = Object.assign([], this.state.graphMessage);
    const arrGraphMessageCategory = Object.assign(
      [],
      this.state.graphMessageCategory
    );
    const arrGraphAcknowledge = Object.assign([], this.state.graphAcknowledge);
    const arrGraphAcknowledgeCategory = Object.assign(
      [],
      this.state.graphAcknowledgeCategory
    );
    const arrGraphAcknowledgeRow = Object.assign(
      [],
      this.state.graphAcknowledgeRow
    );
    const arrGraphAcknowledgeRowCategory = Object.assign(
      [],
      this.state.graphAcknowledgeRowCategory
    );
    const {
      isPie,
      isColumn,

      isLoadMessage,
      isLoadMessageRow,
      isLoadMessageDetail,
      isMessageModal,
      txtFrom,
      txtTo,
      txtCorreltationId,
      txtMessageId,
      txtDeliverysiteCode,
      txtParentOrderId,
      txtOrderId,
      txtOrderType,
      selSenderIndex,
      selMessageTypeIndex,
      selReceiverIndex,
      selAcknowledgeIndex,
      selValidationIndex,
      selSearchTypeIndex,
      selGroupProcessIndex,
      selProcessIndex,

      isLoadMessageAcknowledge,
      isLoadMessageAcknowledgeRow,
      txtSAFrom,
      txtSATo,
      txtSADeliverysiteCode,
      txtSAAcknowledgeId,
      txtSACorreltationId,
      txtSAAckCode,
      txtSAAckText,
      selSASenderIndex,
      selSAReceiverIndex,
      selSAValidationIndex,
      selSAMessageTypeIndex,
      selSAAcknowledgeIndex,

      isLoadMessageGraph,
      txtMDeliverysiteCode,
      txtMFrom,
      txtMTo,
      txtMFromHour,
      txtMFromMin,
      txtMToHour,
      txtMToMin,
      selMSenderIndex,
      selMReceiverIndex,
      selMValidationIndex,
      selMMessageTypeIndex,
      selMAcknowledgeIndex,
      selMSearchTypeIndex,
      selMMessageCategoryIndex,
      selMTimeIndex,

      isLoadAcknowledgeGraph,
      txtADeliverysiteCode,
      txtAAckCode,
      txtAAckText,
      txtAFrom,
      txtATo,
      txtAFromHour,
      txtAToHour,
      txtAFromMin,
      txtAToMin,
      selASenderIndex,
      selAReceiverIndex,
      selAMessageTypeIndex,
      selAMessageCategoryIndex,
      selAAcknowledgeIndex,
      selATimeIndex,

      isLoadAcknowledgeRowGraph,
      txtARDeliverysiteCode,
      txtARAckCode,
      txtARAckText,
      txtARFrom,
      txtARTo,
      txtARFromHour,
      txtARToHour,
      txtARFromMin,
      txtARToMin,
      selARSenderIndex,
      selARReceiverIndex,
      selARMessageTypeIndex,
      selARMessageCategoryIndex,
      selARAcknowledgeIndex,
      selARTimeIndex,
    } = this.state;
    return (
      <>
        <Container>
          <Sidemenu selIndex={6} />
          <div className="content">
            {/* Tabs */}
            <div className="list-group main-tab">
              {tabs.map((item, index) => {
                return (
                  <a
                    className={
                      index === 0 ? "list-group-item active" : "list-group-item"
                    }
                    data-toggle="list"
                    href={item.id}
                    onClick={() => this.setState({ tabIndex: index })}
                  >
                    {item.title}
                  </a>
                );
              })}
            </div>

            <div className="tab-content">
              {/* Tab - 1 */}
              <div className="tab-pane active" id="tab_1">
                {/* Search Panel */}
                <div className="accordion" id="accordionExample">
                  <div className="card">
                    <div
                      className="accordion-header"
                      data-toggle="collapse"
                      data-target="#collapseOne"
                      aria-expanded="true"
                    >
                      Advanced Search
                    </div>
                    <div
                      id="collapseOne"
                      className="accordion-txt collapse show"
                      data-parent="#accordionExample"
                    >
                      <div className="row align-items-center">
                        <div className="col-lg-7">
                          <div className="row align-items-center">
                            <div className="col-2 col-md-1 mb-2 mb-lg-0">
                              <label className="mb-0">From :</label>
                            </div>
                            <div className="col-4 col-md-2 mb-2 mb-lg-0">
                              <div className="date-select">
                                <input
                                  type="date"
                                  name="from"
                                  placeholder=""
                                  className="form-control date"
                                  value={txtFrom}
                                  onChange={(e) =>
                                    this.setState({ txtFrom: e.target.value })
                                  }
                                />
                              </div>
                            </div>
                            <div className="col-6 col-md-3 mb-2 mb-lg-0">
                              <div className="form-group mb-md-0">
                                <input
                                  type="text"
                                  className="form-control"
                                  placeholder="Deliverysite Ean code"
                                  value={txtDeliverysiteCode}
                                  onChange={(e) =>
                                    this.setState({
                                      txtDeliverysiteCode: e.target.value,
                                    })
                                  }
                                />
                              </div>
                            </div>
                            <div className="col-6 col-md-3 mb-2 mb-lg-0">
                              <div className="form-group mb-md-0">
                                <input
                                  type="text"
                                  className="form-control"
                                  placeholder="MessageId"
                                  value={txtMessageId}
                                  onChange={(e) =>
                                    this.setState({
                                      txtMessageId: e.target.value,
                                    })
                                  }
                                />
                              </div>
                            </div>
                            <div className="col-6 col-md-3 mb-2 mb-lg-0">
                              <div className="form-group mb-md-0">
                                <input
                                  type="text"
                                  className="form-control"
                                  placeholder="Correlationld"
                                  value={txtCorreltationId}
                                  onChange={(e) =>
                                    this.setState({
                                      txtCorreltationId: e.target.value,
                                    })
                                  }
                                />
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="col-lg-5">
                          <div className="row align-items-center">
                            <div className="col-6 col-md-4 mb-2 mb-lg-0">
                              <div className="form-group select_grp mb-md-0">
                                <select
                                  className="form-control"
                                  value={selSenderIndex}
                                  onChange={(e) =>
                                    this.setState({
                                      selSenderIndex: e.target.value,
                                    })
                                  }
                                >
                                  <option value={""}>Sending System</option>
                                  {arrSystems.map((item, index) => {
                                    return (
                                      <option value={item.systemId}>
                                        {item.systemName}
                                      </option>
                                    );
                                  })}
                                </select>
                                <button
                                  className="select_reset"
                                  onClick={() => {
                                    this.setState({ selSenderIndex: "" });
                                  }}
                                >
                                  ×
                                </button>
                              </div>
                            </div>
                            <div className="col-6 col-md-4 mb-2 mb-lg-0">
                              <div className="form-group select_grp mb-md-0">
                                <select
                                  className="form-control"
                                  value={selMessageTypeIndex}
                                  onChange={(e) =>
                                    this.setState({
                                      selMessageTypeIndex: e.target.value,
                                    })
                                  }
                                >
                                  <option value={""}>Message Type</option>
                                  {arrMessageTypes.map((item, index) => {
                                    return (
                                      <option value={item.messageType}>
                                        {item.messageType}
                                      </option>
                                    );
                                  })}
                                </select>
                                <button
                                  className="select_reset"
                                  onClick={() => {
                                    this.setState({ selMessageTypeIndex: "" });
                                  }}
                                >
                                  ×
                                </button>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="row align-items-center mt-1">
                        <div className="col-lg-7">
                          <div className="row align-items-center">
                            <div className="col-2 col-md-1 mb-2 mb-lg-0">
                              <label className="mb-0">To :</label>
                            </div>
                            <div className="col-4 col-md-2 mb-2 mb-lg-0">
                              <div className="date-select">
                                <input
                                  type="date"
                                  name="from"
                                  placeholder=""
                                  className="form-control date"
                                  value={txtTo}
                                  onChange={(e) =>
                                    this.setState({
                                      txtTo: e.target.value,
                                    })
                                  }
                                />
                              </div>
                            </div>
                            <div className="col-6 col-md-3 mb-2 mb-lg-0">
                              <div className="form-group mb-md-0">
                                <input
                                  type="text"
                                  className="form-control"
                                  placeholder="OrderId"
                                  value={txtOrderId}
                                  onChange={(e) =>
                                    this.setState({
                                      txtOrderId: e.target.value,
                                    })
                                  }
                                />
                              </div>
                            </div>
                            <div className="col-6 col-md-3 mb-2 mb-lg-0">
                              <div className="form-group mb-md-0">
                                <input
                                  type="text"
                                  className="form-control"
                                  placeholder="Parent Order Id"
                                  value={txtParentOrderId}
                                  onChange={(e) =>
                                    this.setState({
                                      txtParentOrderId: e.target.value,
                                    })
                                  }
                                />
                              </div>
                            </div>
                            <div className="col-6 col-md-3 mb-2 mb-lg-0">
                              <div className="form-group mb-md-0">
                                <input
                                  type="text"
                                  className="form-control"
                                  placeholder="Order Type"
                                  value={txtOrderType}
                                  onChange={(e) =>
                                    this.setState({
                                      txtOrderType: e.target.value,
                                    })
                                  }
                                />
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="col-lg-5">
                          <div className="row align-items-center">
                            <div className="col-6 col-md-4 mb-2 mb-lg-0">
                              <div className="form-group select_grp mb-md-0">
                                <select
                                  className="form-control"
                                  value={selReceiverIndex}
                                  onChange={(e) =>
                                    this.setState({
                                      selReceiverIndex: e.target.value,
                                    })
                                  }
                                >
                                  <option value="">Receiveing System</option>
                                  {arrSystems.map((item, index) => {
                                    return (
                                      <option value={item.systemId}>
                                        {item.systemName}
                                      </option>
                                    );
                                  })}
                                </select>
                                <button
                                  className="select_reset"
                                  onClick={() => {
                                    this.setState({ selReceiverIndex: "" });
                                  }}
                                >
                                  ×
                                </button>
                              </div>
                            </div>
                            <div className="col-6 col-md-4 mb-2 mb-lg-0">
                              <div className="form-group select_grp mb-md-0">
                                <select
                                  className="form-control"
                                  value={selAcknowledgeIndex}
                                  onChange={(e) =>
                                    this.setState({
                                      selAcknowledgeIndex: e.target.value,
                                    })
                                  }
                                >
                                  <option>Acknowledge</option>
                                  {arrAcknowledge.map((item, index) => {
                                    return (
                                      <option value={item.listValueId}>
                                        {item.listValueName || "No Ack"}
                                      </option>
                                    );
                                  })}
                                </select>
                                <button
                                  className="select_reset"
                                  onClick={() => {
                                    this.setState({ selAcknowledgeIndex: "" });
                                  }}
                                >
                                  ×
                                </button>
                              </div>
                            </div>
                            <div className="col-md-4 mb-2 mb-lg-0 text-center">
                              <small>
                                <b>
                                  Number Of Results : {arrMessage.length || 0}
                                </b>
                              </small>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="row align-items-center mt-1">
                        <div className="col-lg-7"></div>
                        <div className="col-lg-5">
                          <div className="row align-items-center">
                            <div className="col-6 col-md-4 mb-2 mb-lg-0">
                              <div className="form-group select_grp mb-md-0">
                                <select
                                  className="form-control"
                                  value={selValidationIndex}
                                  onChange={(e) =>
                                    this.setState({
                                      selValidationIndex: e.target.value,
                                    })
                                  }
                                >
                                  <option value={""}>Message Validation</option>
                                  {arrMessageValidation.map((item, index) => {
                                    return (
                                      <option value={item.listValueId}>
                                        {item.listValueName}
                                      </option>
                                    );
                                  })}
                                </select>
                                <button
                                  className="select_reset"
                                  onClick={() => {
                                    this.setState({ selValidationIndex: "" });
                                  }}
                                >
                                  ×
                                </button>
                              </div>
                            </div>
                            <div className="col-6 col-md-4 mb-2 mb-lg-0">
                              <div className="form-group select_grp mb-md-0">
                                <select
                                  className="form-control"
                                  value={selSearchTypeIndex}
                                  onChange={(e) =>
                                    this.setState({
                                      selSearchTypeIndex: e.target.value,
                                    })
                                  }
                                >
                                  <option value={""}>Search Type</option>
                                  {arrSearchTypes.map((item, index) => {
                                    return (
                                      <option value={item.listValueName}>
                                        {item.listValueName}
                                      </option>
                                    );
                                  })}
                                </select>
                                <button
                                  className="select_reset"
                                  onClick={() => {
                                    this.setState({ selSearchTypeIndex: "" });
                                  }}
                                >
                                  ×
                                </button>
                              </div>
                            </div>
                            <div className="col-md-4 mb-2 mb-lg-0">
                              <button
                                className="btn btn-primary btn-block btn-sm"
                                onClick={() => this.onClickSearch()}
                              >
                                Search
                              </button>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="card">
                    <div
                      className="accordion-header"
                      data-toggle="collapse"
                      data-target="#collapseTwo"
                      aria-expanded="false"
                    >
                      Search By WatchDogs
                    </div>
                    <div
                      id="collapseTwo"
                      className="accordion-txt collapse"
                      data-parent="#accordionExample"
                    >
                      <div className="row align-items-center">
                        <div className="col-md-8">
                          <div className="row align-items-center">
                            <div className="col-6 col-md-3">
                              <div className="form-group select_grp mb-md-0">
                                <select
                                  className="form-control"
                                  value={selGroupProcessIndex}
                                  onChange={(e) =>
                                    this.setState(
                                      {
                                        selGroupProcessIndex: e.target.value,
                                        selProcessIndex: "",
                                      },
                                      () => {
                                        this.onCallGetProcessByID(
                                          e.target.value
                                        );
                                      }
                                    )
                                  }
                                >
                                  <option value={""}>
                                    Select Group Process
                                  </option>
                                  {arrGroupProcess.map((item, index) => {
                                    return (
                                      <option value={item.warningCaseTypeId}>
                                        {item.warningCaseTypeName}
                                      </option>
                                    );
                                  })}
                                </select>
                                <button
                                  className="select_reset"
                                  onClick={() => {
                                    this.setState({
                                      selGroupProcessIndex: "",
                                      selProcessIndex: "",
                                      process: [],
                                    });
                                  }}
                                >
                                  ×
                                </button>
                              </div>
                            </div>
                            <div className="col-6 col-md-3">
                              <div className="form-group select_grp mb-md-0">
                                <select
                                  className="form-control"
                                  value={selProcessIndex}
                                  onChange={(e) =>
                                    this.setState({
                                      selProcessIndex: e.target.value,
                                    })
                                  }
                                >
                                  <option value={""}>Select Process</option>
                                  {arrProcess.map((item, index) => {
                                    return (
                                      <option
                                        value={item.TestCaseProcessOrderId}
                                      >
                                        {item.MessageType}
                                      </option>
                                    );
                                  })}
                                </select>
                                <button
                                  className="select_reset"
                                  onClick={() => {
                                    this.setState({
                                      selProcessIndex: "",
                                    });
                                  }}
                                >
                                  ×
                                </button>
                              </div>
                            </div>
                            <div className="col-md-2">
                              <button className="btn btn-primary btn-block btn-sm">
                                Search
                              </button>
                            </div>
                            <div className="col-md-3">
                              <small>
                                <b>Number Of Results : 0</b>
                              </small>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <hr className="space" />

                <div className="box">
                  <div className="table-responsive">
                    {/* Message Table */}
                    {isLoadMessage ? (
                      <Loader message={"loading message data"} />
                    ) : null}
                    {isLoadMessageDetail ? (
                      <Loader message={"loading message details"} />
                    ) : null}
                    {/* Download XLS */}
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "flex-end",
                      }}
                    >
                      <img
                        src={iconXLS}
                        style={{ width: 20, height: 20, marginBottom: 8 }}
                        alt="export_xls"
                        title={"Export XLS"}
                        onClick={() => this.exportToXLS(arrMessage, "messages")}
                      />
                    </div>
                    <div
                      className="ag-theme-alpine"
                      style={{ height: 450, width: "100%", fontSize: 12 }}
                    >
                      <AgGridReact
                        enableCellTextSelection={true}
                        pagination={true}
                        paginationAutoPageSize={true}
                        rowSelection={"single"}
                        rowData={arrMessage || []}
                        defaultColDef={{
                          resizable: true,
                          suppressSizeToFit: true,
                        }}
                        columnDefs={[
                          {
                            field: "ackResponseCode",
                            width: 90,
                            minWidth: 50,
                            maxWidth: 150,
                            cellRenderer: (params) => {
                              let roundColor = "";
                              if (params.data.ackResponseCode === "red")
                                roundColor =
                                  '<i class="fa fa-circle" style="color:#e32522"></i>';
                              if (params.data.ackResponseCode === "green")
                                roundColor =
                                  '<i class="fa fa-circle" style="color:#4bd640"></i>';
                              if (params.data.ackResponseCode === "orange")
                                roundColor =
                                  '<i class="fa fa-circle" style="color:#ed930d"></i>';
                              return roundColor;
                            },
                          },
                          {
                            field: "xmlValid",
                            width: 90,
                            minWidth: 50,
                            maxWidth: 150,
                            cellRenderer: (params) => {
                              let roundColor = "";
                              if (params.data.xmlValid === "red")
                                roundColor =
                                  '<i class="fa fa-circle" style="color:#e32522"></i>';
                              if (params.data.xmlValid === "green")
                                roundColor =
                                  '<i class="fa fa-circle" style="color:#4bd640"></i>';
                              if (params.data.xmlValid === "orange")
                                roundColor =
                                  '<i class="fa fa-circle" style="color:#ed930d"></i>';
                              return roundColor;
                            },
                          },
                          {
                            field: "_",
                            width: 50,
                            minWidth: 50,
                            maxWidth: 50,
                          },
                          { field: "messageId" },
                          { field: "correltaionId" },
                          {
                            field: "__",
                            width: 50,
                            minWidth: 50,
                            maxWidth: 50,
                          },
                          { field: "acknowledgeId" },
                          { field: "messageCreated" },
                          { field: "messageReceived" },
                          { field: "messageType", minWidth: 220 },
                          { field: "senderSystem" },
                          { field: "receiverSystem" },
                          { field: "internalRectDate" },
                        ]}
                        onCellClicked={(e) => {
                          console.log("cell: ", e);
                          if (e.value === "🔍" && e.column.getColId() === "_") {
                            this.onCallGetMessageBinaryInfo(
                              arrMessage[e.rowIndex].messageId
                            );
                            this.onCallGetMessageRaw(
                              arrMessage[e.rowIndex].messageId
                            );
                          }
                          //this.setState({ isMessageModal: true });
                          if (
                            e.value === "🔍" &&
                            e.column.getColId() === "__"
                          ) {
                            this.onCallGetMessageBinaryInfo(
                              arrMessage[e.rowIndex].acknowledgeId
                            );
                            this.onCallGetMessageRaw(
                              arrMessage[e.rowIndex].acknowledgeId
                            );
                          }
                          //this.setState({ isMessageModal: true });
                        }}
                        onSelectionChanged={(e) => {
                          console.log("row: ", e.api.getSelectedRows()[0]);
                          let selectedRow = e.api.getSelectedRows()[0];
                          this.onCallGetMessagesRows(selectedRow.messageId);
                        }}
                      ></AgGridReact>
                    </div>
                  </div>
                </div>

                <hr className="space" />

                <div className="title">
                  <h2>Messages Rows</h2>
                </div>
                <div className="box">
                  <div className="table-responsive">
                    {isLoadMessageRow ? (
                      <Loader message={"loading message row data"} />
                    ) : null}
                    {/* Download XLS */}
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "flex-end",
                      }}
                    >
                      <img
                        src={iconXLS}
                        style={{ width: 20, height: 20, marginBottom: 8 }}
                        alt="export_xls"
                        title={"Export XLS"}
                        onClick={() =>
                          this.exportToXLS(arrMessageRow, "message_row")
                        }
                      />
                    </div>
                    <div
                      className="ag-theme-alpine"
                      style={{ height: 350, width: "100%", fontSize: 12 }}
                    >
                      <AgGridReact
                        enableCellTextSelection={true}
                        pagination={true}
                        paginationAutoPageSize={true}
                        rowSelection={"single"}
                        rowData={arrMessageRow || []}
                        columnDefs={[
                          { field: "messageId" },
                          { field: "deliverysiteId" },
                          { field: "parentOrderId" },
                          { field: "orderId" },
                          { field: "orderTypeId" },
                          { field: "orderTypeName" },
                          { field: "orderStatusId" },
                          { field: "orderStatus" },
                          { field: "parentOrder" },
                          { field: "parentOrderType" },
                          { field: "deviceId" },
                          { field: "settlementType" },
                          { field: "readingInterval" },
                          { field: "customer" },
                          { field: "network" },
                          { field: "concessionArea" },
                          { field: "meterCapacity" },
                          { field: "meterCapacityDecimal" },
                          { field: "startTime" },
                          { field: "endTime" },
                          { field: "marketActorId" },
                          { field: "balanceActorId" },
                          { field: "distributionActorId" },
                          { field: "interruptionStartTime" },
                          { field: "interruptionEndTime" },
                        ]}
                        defaultColDef={{ resizable: true, sortable: true }}
                        onSelectionChanged={(e) => {
                          console.log("row: ", e.api.getSelectedRows()[0]);
                          let selectedRow = e.api.getSelectedRows()[0];
                          this.manageMessageRowDetail(selectedRow.rowDetail);
                          this.manageMessageRowValueDetail(
                            selectedRow.rowValueDetail
                          );
                        }}
                      ></AgGridReact>
                    </div>
                  </div>
                </div>

                <hr className="space" />

                <div className="title">
                  <h2>Messages Rows Details</h2>
                </div>
                <div className="box">
                  <div className="list-group min-tab">
                    {miniTab.map((item, index) => {
                      return (
                        <a
                          className={
                            index === 0
                              ? "list-group-item active"
                              : "list-group-item"
                          }
                          data-toggle="list"
                          href={item.id}
                          onClick={() => this.setState({ miniTabIndex: index })}
                        >
                          {item.title}
                        </a>
                      );
                    })}
                  </div>
                  <div className="tab-content">
                    <div className="tab-pane active" id="min_tab_1">
                      <div className="table-responsive">
                        {/* Download XLS */}
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "flex-end",
                          }}
                        >
                          <img
                            src={iconXLS}
                            style={{ width: 20, height: 20, marginBottom: 8 }}
                            alt="export_xls"
                            title={"Export XLS"}
                            onClick={() =>
                              this.exportToXLS(
                                arrMessageRowDetail,
                                "message_row_detail"
                              )
                            }
                          />
                        </div>
                        <div
                          className="ag-theme-alpine"
                          style={{ height: 300, width: "100%", fontSize: 12 }}
                        >
                          <AgGridReact
                            enableCellTextSelection={true}
                            pagination={true}
                            paginationAutoPageSize={true}
                            rowSelection={"single"}
                            rowData={arrMessageRowDetail || []}
                            columnDefs={[
                              { field: "actionId" },
                              { field: "action" },
                              { field: "mandatory" },
                              { field: "target" },
                              { field: "performed" },
                              { field: "user" },
                              { field: "responseCode" },
                              { field: "comment" },
                            ]}
                            defaultColDef={{ resizable: true, sortable: true }}
                            onSelectionChanged={(e) => {}}
                          ></AgGridReact>
                        </div>
                      </div>
                    </div>
                    <div className="tab-pane" id="min_tab_2">
                      <div className="table-responsive">
                        <div
                          className="ag-theme-alpine"
                          style={{ height: 300, width: "100%", fontSize: 12 }}
                        >
                          <AgGridReact
                            enableCellTextSelection={true}
                            pagination={true}
                            paginationAutoPageSize={true}
                            rowSelection={"single"}
                            rowData={arrMessageRowValueDetail || []}
                            columnDefs={[
                              { field: "readingTime" },
                              { field: "reading" },
                              { field: "internalStatusId" },
                              { field: "svkCode" },
                              { field: "externalStatusId" },
                              { field: "readingTypeId" },
                              { field: "readingReasonId" },
                              { field: "consumptionStartTime" },
                              { field: "consumptionEndTime" },
                              { field: "consumption" },
                              { field: "consumptionStatusId" },
                              { field: "consumptionTypeId" },
                              { field: "exported" },
                            ]}
                            defaultColDef={{ resizable: true, sortable: true }}
                            onSelectionChanged={(e) => {}}
                          ></AgGridReact>
                        </div>
                      </div>
                    </div>
                    <div className="tab-pane" id="min_tab_3">
                      <div className="table-responsive">
                        <div
                          className="ag-theme-alpine"
                          style={{ height: 300, width: "100%", fontSize: 12 }}
                        >
                          <AgGridReact
                            enableCellTextSelection={true}
                            pagination={true}
                            paginationAutoPageSize={true}
                            rowSelection={"single"}
                            rowData={[]}
                            columnDefs={[
                              { field: "componentId" },
                              { field: "componentName" },
                              { field: "price" },
                              { field: "quantity" },
                              { field: "signedBy" },
                              { field: "changed" },
                              { field: "exported" },
                            ]}
                            defaultColDef={{ resizable: true, sortable: true }}
                            onSelectionChanged={(e) => {}}
                          ></AgGridReact>
                        </div>
                      </div>
                    </div>
                    <div className="tab-pane" id="min_tab_4">
                      <div className="table-responsive">
                        <div
                          className="ag-theme-alpine"
                          style={{ height: 300, width: "100%", fontSize: 12 }}
                        >
                          <AgGridReact
                            enableCellTextSelection={true}
                            pagination={true}
                            paginationAutoPageSize={true}
                            rowSelection={"single"}
                            rowData={[]}
                            columnDefs={[
                              { field: "id" },
                              { field: "name" },
                              { field: "price" },
                              { field: "quantity" },
                              { field: "exported" },
                            ]}
                            defaultColDef={{ resizable: true, sortable: true }}
                            onSelectionChanged={(e) => {}}
                          ></AgGridReact>
                        </div>
                      </div>
                    </div>
                    <div className="tab-pane" id="min_tab_5">
                      <div className="table-responsive">
                        <div
                          className="ag-theme-alpine"
                          style={{ height: 300, width: "100%", fontSize: 12 }}
                        >
                          <AgGridReact
                            enableCellTextSelection={true}
                            pagination={true}
                            paginationAutoPageSize={true}
                            rowSelection={"single"}
                            rowData={[]}
                            columnDefs={[
                              { field: "id" },
                              { field: "name" },
                              { field: "available" },
                              { field: "activated" },
                              { field: "exported" },
                            ]}
                            defaultColDef={{ resizable: true, sortable: true }}
                            onSelectionChanged={(e) => {}}
                          ></AgGridReact>
                        </div>
                      </div>
                    </div>
                    <div className="tab-pane" id="min_tab_6">
                      <div className="table-responsive">
                        <div
                          className="ag-theme-alpine"
                          style={{ height: 300, width: "100%", fontSize: 12 }}
                        >
                          <AgGridReact
                            enableCellTextSelection={true}
                            pagination={true}
                            paginationAutoPageSize={true}
                            rowSelection={"single"}
                            rowData={[]}
                            columnDefs={[
                              { field: "id" },
                              { field: "name" },
                              { field: "exported" },
                            ]}
                            defaultColDef={{ resizable: true, sortable: true }}
                            onSelectionChanged={(e) => {}}
                          ></AgGridReact>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div className="tab-pane " id="tab_2">
                <div className="box">
                  <div className="row align-items-center">
                    <div className="col-md-7">
                      <div className="row align-items-center">
                        <div className="col-2 col-md-1">
                          <label className="mb-0">From :</label>
                        </div>
                        <div className="col-4 col-md-2">
                          <div className="date-select">
                            <input
                              type="date"
                              name="from"
                              placeholder=""
                              className="form-control date"
                              value={txtSAFrom}
                              onChange={(e) =>
                                this.setState({ txtSAFrom: e.target.value })
                              }
                            />
                          </div>
                        </div>
                        <div className="col-6 col-md-3">
                          <div className="form-group mb-md-0">
                            <input
                              type="text"
                              className="form-control"
                              placeholder="Deliverysite Ean code"
                              value={txtSADeliverysiteCode}
                              onChange={(e) =>
                                this.setState({
                                  txtSADeliverysiteCode: e.target.value,
                                })
                              }
                            />
                          </div>
                        </div>
                        <div className="col-6 col-md-3">
                          <div className="form-group mb-md-0">
                            <input
                              type="text"
                              className="form-control"
                              placeholder="Acknowledge Id"
                              value={txtSAAcknowledgeId}
                              onChange={(e) =>
                                this.setState({
                                  txtSAAcknowledgeId: e.target.value,
                                })
                              }
                            />
                          </div>
                        </div>
                        <div className="col-6 col-md-3">
                          <div className="form-group mb-md-0">
                            <input
                              type="text"
                              className="form-control"
                              placeholder="Correlation Id"
                              value={txtSACorreltationId}
                              onChange={(e) =>
                                this.setState({
                                  txtSACorreltationId: e.target.value,
                                })
                              }
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="col-md-5">
                      <div className="row align-items-center">
                        <div className="col-6 col-md-4">
                          <div className="form-group select_grp mb-md-0">
                            <select
                              className="form-control"
                              value={selSASenderIndex}
                              onChange={(e) =>
                                this.setState({
                                  selSASenderIndex: e.target.value,
                                })
                              }
                            >
                              <option value={""}>Sending System</option>
                              {arrSystems.map((item, index) => {
                                return (
                                  <option value={item.systemId}>
                                    {item.systemName}
                                  </option>
                                );
                              })}
                            </select>
                            <button
                              className="select_reset"
                              onClick={() => {
                                this.setState({ selSASenderIndex: "" });
                              }}
                            >
                              ×
                            </button>
                          </div>
                        </div>
                        <div className="col-6 col-md-4">
                          <div className="form-group select_grp mb-md-0">
                            <select
                              className="form-control"
                              value={selSAMessageTypeIndex}
                              onChange={(e) =>
                                this.setState({
                                  selSAMessageTypeIndex: e.target.value,
                                })
                              }
                            >
                              <option value={""}>Message Type</option>
                              {arrAcknowledgeMessageTypes.map((item, index) => {
                                return (
                                  <option value={item.messageType}>
                                    {item.messageType}
                                  </option>
                                );
                              })}
                            </select>
                            <button
                              className="select_reset"
                              onClick={() => {
                                this.setState({ selSAMessageTypeIndex: "" });
                              }}
                            >
                              ×
                            </button>
                          </div>
                        </div>
                        <div className="col-md-4">
                          <button
                            className="btn btn-primary btn-block btn-sm"
                            onClick={() => {
                              this.onCallGetMessageAcknowledge();
                              this.onCallGetAcknowledgeMessageRows();
                            }}
                          >
                            Search
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="row align-items-center mt-1">
                    <div className="col-md-7">
                      <div className="row align-items-center">
                        <div className="col-2 col-md-1">
                          <label className="mb-md-0">To :</label>
                        </div>
                        <div className="col-4 col-md-2">
                          <div className="date-select">
                            <input
                              type="date"
                              name="from"
                              placeholder=""
                              className="form-control date"
                              value={txtSATo}
                              onChange={(e) =>
                                this.setState({
                                  txtSATo: e.target.value,
                                })
                              }
                            />
                          </div>
                        </div>
                        <div className="col-6 col-md-3">
                          <div className="form-group mb-md-0">
                            <input
                              type="text"
                              className="form-control"
                              placeholder="Ack Code"
                              value={txtSAAckCode}
                              onChange={(e) =>
                                this.setState({
                                  txtSAAckCode: e.target.value,
                                })
                              }
                            />
                          </div>
                        </div>
                        <div className="col-6 col-md-3">
                          <div className="form-group mb-md-0">
                            <input
                              type="text"
                              className="form-control"
                              placeholder="Ack Text"
                              value={txtSAAckText}
                              onChange={(e) =>
                                this.setState({
                                  txtSAAckText: e.target.value,
                                })
                              }
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="col-md-5">
                      <div className="row align-items-center">
                        <div className="col-6 col-md-4">
                          <div className="form-group select_grp mb-md-0">
                            <select
                              className="form-control"
                              value={selSAReceiverIndex}
                              onChange={(e) =>
                                this.setState({
                                  selSAReceiverIndex: e.target.value,
                                })
                              }
                            >
                              <option value={""}>Receiving System</option>
                              {arrSystems.map((item, index) => {
                                return (
                                  <option value={item.systemId}>
                                    {item.systemName}
                                  </option>
                                );
                              })}
                            </select>
                            <button
                              className="select_reset"
                              onClick={() => {
                                this.setState({ selSAReceiverIndex: "" });
                              }}
                            >
                              ×
                            </button>
                          </div>
                        </div>
                        <div className="col-6 col-md-4">
                          <div className="form-group select_grp mb-md-0">
                            <select
                              className="form-control"
                              value={selSAAcknowledgeIndex}
                              onChange={(e) =>
                                this.setState({
                                  selSAAcknowledgeIndex: e.target.value,
                                })
                              }
                            >
                              <option>Acknowledge</option>
                              {arrAcknowledge.map((item, index) => {
                                return (
                                  <option value={item.listValueId}>
                                    {item.listValueName || "No Ack"}
                                  </option>
                                );
                              })}
                            </select>
                            <button
                              className="select_reset"
                              onClick={() => {
                                this.setState({ selSAAcknowledgeIndex: "" });
                              }}
                            >
                              ×
                            </button>
                          </div>
                        </div>
                        <div className="col-md-4 text-center">
                          <small>
                            <b>
                              {"Number Of Results : " +
                                arrMessageAcknowledge.length}
                            </b>
                          </small>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="row align-items-center mt-1">
                    <div className="col-md-7"></div>
                    <div className="col-md-5">
                      <div className="row align-items-center">
                        <div className="col-6 col-md-4">
                          <div className="form-group select_grp mb-md-0">
                            <select
                              className="form-control"
                              value={selSAValidationIndex}
                              onChange={(e) =>
                                this.setState({
                                  selSAValidationIndex: e.target.value,
                                })
                              }
                            >
                              <option value={""}>Message Validation</option>
                              {arrMessageValidation.map((item, index) => {
                                return (
                                  <option value={item.listValueId}>
                                    {item.listValueName}
                                  </option>
                                );
                              })}
                            </select>
                            <button
                              className="select_reset"
                              onClick={() => {
                                this.setState({ selSAValidationIndex: "" });
                              }}
                            >
                              ×
                            </button>
                          </div>
                        </div>
                        <div className="col-6 col-md-4"></div>
                        <div className="col-md-4 text-center">
                          <small>
                            <b>
                              Number Of Row Results :{" "}
                              {arrMessageAcknowledgeRow.length}
                            </b>
                          </small>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <hr className="space" />

                <div className="box">
                  <div className="list-group min-tab">
                    <a
                      className="list-group-item active"
                      data-toggle="list"
                      href="#min1_tab_1"
                    >
                      Acknowledge message view
                    </a>
                    <a
                      className="list-group-item"
                      data-toggle="list"
                      href="#min1_tab_2"
                    >
                      Acknowledge row view
                    </a>
                  </div>
                  <div className="tab-content">
                    <div className="tab-pane active" id="min1_tab_1">
                      <div className="table-responsive">
                        {isLoadMessageAcknowledge ? (
                          <Loader
                            message={"loading acknowledge message data"}
                          />
                        ) : null}
                        {/* Download XLS */}
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "flex-end",
                          }}
                        >
                          <img
                            src={iconXLS}
                            style={{ width: 20, height: 20, marginBottom: 8 }}
                            alt="export_xls"
                            title={"Export XLS"}
                            onClick={() =>
                              this.exportToXLS(
                                arrMessageAcknowledge,
                                "acknowledge_messages"
                              )
                            }
                          />
                        </div>
                        <div
                          className="ag-theme-alpine"
                          style={{ height: 350, width: "100%", fontSize: 12 }}
                        >
                          <AgGridReact
                            enableCellTextSelection={true}
                            pagination={true}
                            paginationAutoPageSize={true}
                            rowSelection={"single"}
                            rowData={arrMessageAcknowledge || []}
                            columnDefs={[
                              { field: "_", width: 80 },
                              { field: "acknowledgeId" },
                              { field: "__", width: 80 },
                              { field: "correltaionId" },
                              { field: "dispatchId" },
                              { field: "messageCreated" },
                              { field: "messageReceived" },
                              { field: "messageType" },
                              { field: "messageSenderSystem" },
                              { field: "messageReceiverSystem" },
                              { field: "recordCount" },
                              { field: "internalRectDate" },
                            ]}
                            defaultColDef={{ resizable: true, sortable: true }}
                            onCellClicked={(e) => {
                              console.log("cell: ", e);
                              if (
                                e.value === "🔍" &&
                                e.column.getColId() === "_"
                              ) {
                                this.onCallGetMessageBinaryInfo(
                                  arrMessageAcknowledge[e.rowIndex]
                                    .acknowledgeId
                                );
                                this.onCallGetMessageRaw(
                                  arrMessageAcknowledge[e.rowIndex]
                                    .acknowledgeId
                                );
                              }
                              //this.setState({ isMessageModal: true });
                              if (
                                e.value === "🔍" &&
                                e.column.getColId() === "__"
                              ) {
                                this.onCallGetMessageBinaryInfo(
                                  arrMessageAcknowledge[e.rowIndex]
                                    .correltaionId
                                );
                                this.onCallGetMessageRaw(
                                  arrMessageAcknowledge[e.rowIndex]
                                    .correltaionId
                                );
                              }
                              //this.setState({ isMessageModal: true });
                            }}
                            onSelectionChanged={(e) => {
                              console.log("row: ", e.api.getSelectedRows()[0]);
                              let selectedRow = e.api.getSelectedRows()[0];
                              // this.manageMessageRowDetail(
                              //   selectedRow.rowDetail
                              // );
                            }}
                          ></AgGridReact>
                        </div>
                      </div>
                    </div>
                    <div className="tab-pane" id="min1_tab_2">
                      <div className="table-responsive">
                        {/* Download XLS */}
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "flex-end",
                          }}
                        >
                          <img
                            src={iconXLS}
                            style={{ width: 20, height: 20, marginBottom: 8 }}
                            alt="export_xls"
                            title={"Export XLS"}
                            onClick={() =>
                              this.exportToXLS(
                                arrMessageAcknowledgeRow,
                                "acknowledge_row"
                              )
                            }
                          />
                        </div>
                        <div
                          className="ag-theme-alpine"
                          style={{ height: 350, width: "100%", fontSize: 12 }}
                        >
                          <AgGridReact
                            enableCellTextSelection={true}
                            pagination={true}
                            paginationAutoPageSize={true}
                            rowSelection={"single"}
                            rowData={arrMessageAcknowledgeRow || []}
                            columnDefs={[
                              { field: "_", width: 80 },
                              { field: "acknowledgeId" },
                              { field: "__", width: 80 },
                              { field: "correltaionId" },
                              { field: "deliverysiteId" },
                              { field: "orderId" },
                              { field: "responseCode" },
                              { field: "responseText" },
                              { field: "recordPosition" },
                              { field: "position" },
                              { field: "messageCreated" },
                              { field: "messageReceived" },
                              { field: "messageType" },
                              { field: "messageReceiverSystem" },
                              { field: "messageSenderSystem" },
                            ]}
                            defaultColDef={{ resizable: true, sortable: true }}
                            onSelectionChanged={(e) => {
                              console.log("row: ", e.api.getSelectedRows()[0]);
                              let selectedRow = e.api.getSelectedRows()[0];
                              // this.manageMessageRowDetail(
                              //   selectedRow.rowDetail
                              // );
                            }}
                          ></AgGridReact>
                        </div>
                        {/* <table
                          className="table table-striped table-bordered data-table"
                          style={{ width: "100%" }}
                        >
                          <thead>
                            <tr>
                              <th>Name</th>
                              <th>Position</th>
                              <th>Office</th>
                              <th>Age</th>
                              <th>Start date</th>
                              <th>Salary</th>
                            </tr>
                          </thead>
                          <tbody>
                            <tr>
                              <td>Tiger Nixon</td>
                              <td>System Architect</td>
                              <td>Edinburgh</td>
                              <td>61</td>
                              <td>2011/04/25</td>
                              <td>$320,800</td>
                            </tr>
                            <tr>
                              <td>Garrett Winters</td>
                              <td>Accountant</td>
                              <td>Tokyo</td>
                              <td>63</td>
                              <td>2011/07/25</td>
                              <td>$170,750</td>
                            </tr>
                            <tr>
                              <td>Ashton Cox</td>
                              <td>Junior Technical Author</td>
                              <td>San Francisco</td>
                              <td>66</td>
                              <td>2009/01/12</td>
                              <td>$86,000</td>
                            </tr>
                            <tr>
                              <td>Cedric Kelly</td>
                              <td>Senior Javascript Developer</td>
                              <td>Edinburgh</td>
                              <td>22</td>
                              <td>2012/03/29</td>
                              <td>$433,060</td>
                            </tr>
                            <tr>
                              <td>Airi Satou</td>
                              <td>Accountant</td>
                              <td>Tokyo</td>
                              <td>33</td>
                              <td>2008/11/28</td>
                              <td>$162,700</td>
                            </tr>
                            <tr>
                              <td>Brielle Williamson</td>
                              <td>Integration Specialist</td>
                              <td>New York</td>
                              <td>61</td>
                              <td>2012/12/02</td>
                              <td>$372,000</td>
                            </tr>
                            <tr>
                              <td>Colleen Hurst</td>
                              <td>Javascript Developer</td>
                              <td>San Francisco</td>
                              <td>39</td>
                              <td>2009/09/15</td>
                              <td>$205,500</td>
                            </tr>
                            <tr>
                              <td>Sonya Frost</td>
                              <td>Software Engineer</td>
                              <td>Edinburgh</td>
                              <td>23</td>
                              <td>2008/12/13</td>
                              <td>$103,600</td>
                            </tr>
                            <tr>
                              <td>Shad Decker</td>
                              <td>Regional Director</td>
                              <td>Edinburgh</td>
                              <td>51</td>
                              <td>2008/11/13</td>
                              <td>$183,000</td>
                            </tr>
                            <tr>
                              <td>Michael Bruce</td>
                              <td>Javascript Developer</td>
                              <td>Singapore</td>
                              <td>29</td>
                              <td>2011/06/27</td>
                              <td>$183,000</td>
                            </tr>
                            <tr>
                              <td>Donna Snider</td>
                              <td>Customer Support</td>
                              <td>New York</td>
                              <td>27</td>
                              <td>2011/01/25</td>
                              <td>$112,000</td>
                            </tr>
                          </tbody>
                        </table> */}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="tab-pane" id="tab_3">
                <div className="row align-items-center">
                  <div className="col-md-6">
                    <div className="box">
                      <div className="row align-items-center">
                        <div className="col-2 col-md-1">
                          <label className="mb-0">From</label>
                        </div>
                        <div className="col-4 col-md-2">
                          <div className="date-select">
                            <input
                              type="date"
                              name="from"
                              placeholder=""
                              className="form-control date"
                              value={txtMFrom}
                              onChange={(e) =>
                                this.setState({
                                  txtMFrom: e.target.value,
                                })
                              }
                            />
                          </div>
                        </div>
                        <div className="col-2 col-md-1">
                          <label className="mb-0">Hour</label>
                        </div>
                        <div className="col-4 col-md-2">
                          <div className="form-group mb-md-0">
                            <input
                              disabled={
                                selMTimeIndex === "4" || selMTimeIndex === "5"
                                  ? false
                                  : true
                              }
                              type="number"
                              className="form-control"
                              placeholder="0"
                              value={txtMFromHour}
                              onChange={(e) =>
                                this.setState({
                                  txtMFromHour: e.target.value,
                                })
                              }
                            />
                          </div>
                        </div>
                        <div className="col-2 col-md-1">
                          <label className="mb-0">Minute</label>
                        </div>
                        <div className="col-4 col-md-2">
                          <div className="form-group mb-md-0">
                            <input
                              disabled={selMTimeIndex === "5" ? false : true}
                              type="number"
                              className="form-control"
                              placeholder="0"
                              value={txtMFromMin}
                              onChange={(e) =>
                                this.setState({
                                  txtMFromMin: e.target.value,
                                })
                              }
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="col-md-6">
                    <div className="box">
                      <div className="row align-items-center">
                        <div className="col-2 col-md-1">
                          <label className="mb-0">To</label>
                        </div>
                        <div className="col-4 col-md-2">
                          <div className="date-select">
                            <input
                              type="date"
                              name="from"
                              placeholder=""
                              className="form-control date"
                              value={txtMTo}
                              onChange={(e) =>
                                this.setState({
                                  txtMTo: e.target.value,
                                })
                              }
                            />
                          </div>
                        </div>
                        <div className="col-2 col-md-1">
                          <label className="mb-0">Hour</label>
                        </div>
                        <div className="col-4 col-md-2">
                          <div className="form-group mb-md-0">
                            <input
                              disabled={
                                selMTimeIndex === "4" || selMTimeIndex === "5"
                                  ? false
                                  : true
                              }
                              type="number"
                              className="form-control"
                              placeholder="0"
                              value={txtMToHour}
                              onChange={(e) =>
                                this.setState({
                                  txtMToHour: e.target.value,
                                })
                              }
                            />
                          </div>
                        </div>
                        <div className="col-2 col-md-1">
                          <label className="mb-0">Minute</label>
                        </div>
                        <div className="col-4 col-md-2">
                          <div className="form-group mb-md-0">
                            <input
                              disabled={selMTimeIndex === "5" ? false : true}
                              type="number"
                              className="form-control"
                              placeholder="0"
                              value={txtMToMin}
                              onChange={(e) =>
                                this.setState({
                                  txtMToMin: e.target.value,
                                })
                              }
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="box mt-2">
                  <div className="row align-items-center">
                    <div className="col-6 col-md-2 mb-md-1">
                      <div className="form-group mb-md-0">
                        <input
                          type="text"
                          className="form-control"
                          placeholder="Deliverysite Ean code"
                          value={txtMDeliverysiteCode}
                          onChange={(e) =>
                            this.setState({
                              txtMDeliverysiteCode: e.target.value,
                            })
                          }
                        />
                      </div>
                    </div>
                    <div className="col-6 col-md-2 mb-md-1">
                      <div className="form-group select_grp mb-md-0">
                        <select
                          className="form-control"
                          value={selMSenderIndex}
                          onChange={(e) =>
                            this.setState({
                              selMSenderIndex: e.target.value,
                            })
                          }
                        >
                          <option value={""}>Sending System</option>
                          {arrSystems.map((item, index) => {
                            return (
                              <option value={item.systemId}>
                                {item.systemName}
                              </option>
                            );
                          })}
                        </select>
                        <button
                          className="select_reset"
                          onClick={() => {
                            this.setState({ selMSenderIndex: "" });
                          }}
                        >
                          ×
                        </button>
                      </div>
                    </div>
                    <div className="col-6 col-md-2 mb-md-1">
                      <div className="form-group select_grp mb-md-0">
                        <select
                          className="form-control"
                          value={selMAcknowledgeIndex}
                          onChange={(e) =>
                            this.setState({
                              selMAcknowledgeIndex: e.target.value,
                            })
                          }
                        >
                          <option>Acknowledge</option>
                          {arrAcknowledge.map((item, index) => {
                            return (
                              <option value={item.listValueId}>
                                {item.listValueName || "No Ack"}
                              </option>
                            );
                          })}
                        </select>
                        <button
                          className="select_reset"
                          onClick={() => {
                            this.setState({ selMAcknowledgeIndex: "" });
                          }}
                        >
                          ×
                        </button>
                      </div>
                    </div>
                    <div className="col-6 col-md-2 mb-md-1">
                      <div className="form-group select_grp mb-md-0">
                        <select
                          className="form-control"
                          value={selMTimeIndex}
                          onChange={(e) =>
                            this.setState({
                              selMTimeIndex: e.target.value,
                            })
                          }
                        >
                          <option value={""}>Resolution</option>
                          {arrTimes.map((item, index) => {
                            return (
                              <option value={item.listValueId}>
                                {item.listValueName}
                              </option>
                            );
                          })}
                        </select>
                        <button
                          className="select_reset"
                          onClick={() => {
                            this.setState({ selMTimeIndex: "" });
                          }}
                        >
                          ×
                        </button>
                      </div>
                    </div>
                    <div className="col-6 col-md-2 mb-md-1">
                      <div className="form-group select_grp mb-md-0">
                        <select
                          className="form-control"
                          value={selMReceiverIndex}
                          onChange={(e) =>
                            this.setState({
                              selMReceiverIndex: e.target.value,
                            })
                          }
                        >
                          <option value={""}>Recieving System</option>
                          {arrSystems.map((item, index) => {
                            return (
                              <option value={item.systemId}>
                                {item.systemName}
                              </option>
                            );
                          })}
                        </select>
                        <button
                          className="select_reset"
                          onClick={() => {
                            this.setState({ selMReceiverIndex: "" });
                          }}
                        >
                          ×
                        </button>
                      </div>
                    </div>
                    <div className="col-6 col-md-2 mb-md-1">
                      <div className="form-group select_grp mb-md-0">
                        <select
                          className="form-control"
                          value={selMMessageTypeIndex}
                          onChange={(e) =>
                            this.setState({
                              selMMessageTypeIndex: e.target.value,
                            })
                          }
                        >
                          <option value={""}>Message Type</option>
                          {arrMessageTypes.map((item, index) => {
                            return (
                              <option value={item.messageType}>
                                {item.messageType}
                              </option>
                            );
                          })}
                        </select>
                        <button
                          className="select_reset"
                          onClick={() => {
                            this.setState({ selMMessageTypeIndex: "" });
                          }}
                        >
                          ×
                        </button>
                      </div>
                    </div>
                    <div className="col-6 col-md-2 mb-md-1">
                      <div className="form-group select_grp mb-md-0">
                        <select
                          className="form-control"
                          value={selMSearchTypeIndex}
                          onChange={(e) =>
                            this.setState({
                              selMSearchTypeIndex: e.target.value,
                            })
                          }
                        >
                          <option value={""}>Search Type</option>
                          {arrSearchTypes.map((item, index) => {
                            return (
                              <option value={item.listValueName}>
                                {item.listValueName}
                              </option>
                            );
                          })}
                        </select>
                        <button
                          className="select_reset"
                          onClick={() => {
                            this.setState({ selMSearchTypeIndex: "" });
                          }}
                        >
                          ×
                        </button>
                      </div>
                    </div>
                    <div className="col-6 col-md-2 mb-md-1">
                      <div className="form-group select_grp mb-md-0">
                        <select
                          className="form-control"
                          value={selMValidationIndex}
                          onChange={(e) =>
                            this.setState({
                              selMValidationIndex: e.target.value,
                            })
                          }
                        >
                          <option value={""}>Message Validation</option>
                          {arrMessageValidation.map((item, index) => {
                            return (
                              <option value={item.listValueId}>
                                {item.listValueName}
                              </option>
                            );
                          })}
                        </select>
                        <button
                          className="select_reset"
                          onClick={() => {
                            this.setState({ selMValidationIndex: "" });
                          }}
                        >
                          ×
                        </button>
                      </div>
                    </div>
                    <div className="col-6 col-md-2 mb-md-1">
                      <div className="form-group select_grp mb-md-0">
                        <select
                          className="form-control"
                          value={selMMessageCategoryIndex}
                          onChange={(e) =>
                            this.setState({
                              selMMessageCategoryIndex: e.target.value,
                            })
                          }
                        >
                          <option value={""}>Message Category</option>
                          {arrMessageCategory.map((item, index) => {
                            return (
                              <option value={item.messageCategory}>
                                {item.messageCategory}
                              </option>
                            );
                          })}
                        </select>
                        <button
                          className="select_reset"
                          onClick={() => {
                            this.setState({ selMMessageCategoryIndex: "" });
                          }}
                        >
                          ×
                        </button>
                      </div>
                    </div>
                    <div className=" col-md-4 mb-md-1">
                      <div className="d-flex flex-wrap">
                        <div className="custom-control custom-radio mr-3">
                          <input
                            type="radio"
                            className="custom-control-input"
                            id="customControlValidation1"
                            name="radio-stacked"
                            checked={!isPie ? true : false}
                            required=""
                            onClick={() =>
                              this.setState({ isPie: false, isColumn: true })
                            }
                          />
                          <label
                            className="custom-control-label"
                            for="customControlValidation1"
                          >
                            Column Chart
                          </label>
                        </div>
                        <div className="custom-control custom-radio">
                          <input
                            type="radio"
                            className="custom-control-input"
                            id="customControlValidation2"
                            name="radio-stacked"
                            required=""
                            checked={isPie ? true : false}
                            onClick={() =>
                              this.setState({ isPie: true, isColumn: false })
                            }
                          />
                          <label
                            className="custom-control-label"
                            for="customControlValidation2"
                          >
                            Pie Chart
                          </label>
                        </div>
                      </div>
                    </div>
                    <div className="col-md-2 col-lg-1 mb-md-1 ml-auto">
                      <button
                        className="btn btn-primary btn-block btn-sm"
                        onClick={() => this.onCallGetGraphMessages()}
                      >
                        Search
                      </button>
                    </div>
                    <div className="col-md-3 mb-md-1">
                      <small>
                        <b>Number Of Results : 0</b>
                      </small>
                    </div>
                  </div>
                </div>

                <hr className="space" />

                <div className="box">
                  {isLoadMessageGraph ? (
                    <Loader message={"loading message graph data"} />
                  ) : null}
                  {isPie ? (
                    <ReactApexChart
                      options={{
                        colors: ["#e62e00", "#32a852", "#ffa52f"],
                        chart: {
                          width: 580,
                          type: "pie",
                        },
                        labels: ["Not Acked", "Acked", "Nacked"],
                        responsive: [
                          {
                            breakpoint: 680,
                            options: {
                              chart: {
                                width: 340,
                              },
                              legend: {
                                position: "bottom",
                              },
                            },
                          },
                        ],
                      }}
                      series={arrGraphPieMessage}
                      type="pie"
                      width={580}
                    />
                  ) : null}
                  {isColumn ? (
                    <ReactApexChart
                      options={{
                        colors: ["#e62e00", "#32a852", "#ffa52f"],
                        chart: {
                          type: "bar",
                          height: 500,
                          stacked: true,
                          toolbar: {
                            show: true,
                          },
                          zoom: {
                            enabled: true,
                          },
                        },
                        responsive: [
                          {
                            breakpoint: 480,
                            options: {
                              legend: {
                                position: "bottom",
                                offsetX: -10,
                                offsetY: 0,
                              },
                            },
                          },
                        ],
                        plotOptions: {
                          bar: {
                            horizontal: false,
                          },
                        },
                        xaxis: {
                          //type: "datetime",
                          categories: arrGraphMessageCategory || [],
                        },
                        legend: {
                          position: "right",
                          offsetY: 40,
                        },
                        fill: {
                          opacity: 1,
                        },
                      }}
                      series={arrGraphMessage}
                      type="bar"
                      height={500}
                    />
                  ) : null}
                </div>
              </div>
              <div className="tab-pane" id="tab_4">
                <div className="row align-items-center mb-2">
                  <div className="col-md-6">
                    <div className="box">
                      <div className="row align-items-center">
                        <div className="col-2 col-md-1">
                          <label className="mb-0">From</label>
                        </div>
                        <div className="col-4 col-md-2">
                          <div className="date-select">
                            <input
                              type="date"
                              name="from"
                              placeholder=""
                              className="form-control date"
                              value={txtAFrom}
                              onChange={(e) =>
                                this.setState({
                                  txtAFrom: e.target.value,
                                })
                              }
                            />
                          </div>
                        </div>
                        <div className="col-2 col-md-1">
                          <label className="mb-0">Hour</label>
                        </div>
                        <div className="col-4 col-md-2">
                          <div className="form-group mb-md-0">
                            <input
                              disabled={
                                selATimeIndex === "4" || selATimeIndex === "5"
                                  ? false
                                  : true
                              }
                              type="number"
                              className="form-control"
                              placeholder="0"
                              value={txtAFromHour}
                              onChange={(e) =>
                                this.setState({
                                  txtAFromHour: e.target.value,
                                })
                              }
                            />
                          </div>
                        </div>
                        <div className="col-2 col-md-1">
                          <label className="mb-0">Minute</label>
                        </div>
                        <div className="col-4 col-md-2">
                          <div className="form-group mb-md-0">
                            <input
                              disabled={selATimeIndex === "5" ? false : true}
                              type="number"
                              className="form-control"
                              placeholder="0"
                              value={txtAFromMin}
                              onChange={(e) =>
                                this.setState({
                                  txtAFromMin: e.target.value,
                                })
                              }
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="col-md-6">
                    <div className="box">
                      <div className="row align-items-center">
                        <div className="col-2 col-md-1">
                          <label className="mb-0">To</label>
                        </div>
                        <div className="col-4 col-md-2">
                          <div className="date-select">
                            <input
                              type="date"
                              name="from"
                              placeholder=""
                              className="form-control date"
                              value={txtATo}
                              onChange={(e) =>
                                this.setState({
                                  txtATo: e.target.value,
                                })
                              }
                            />
                          </div>
                        </div>
                        <div className="col-2 col-md-1">
                          <label className="mb-0">Hour</label>
                        </div>
                        <div className="col-4 col-md-2">
                          <div className="form-group mb-md-0">
                            <input
                              disabled={
                                selATimeIndex === "4" || selATimeIndex === "5"
                                  ? false
                                  : true
                              }
                              type="number"
                              className="form-control"
                              placeholder="0"
                              value={txtAToHour}
                              onChange={(e) =>
                                this.setState({
                                  txtAToHour: e.target.value,
                                })
                              }
                            />
                          </div>
                        </div>
                        <div className="col-2 col-md-1">
                          <label className="mb-0">Minute</label>
                        </div>
                        <div className="col-4 col-md-2">
                          <div className="form-group mb-md-0">
                            <input
                              disabled={selATimeIndex === "5" ? false : true}
                              type="number"
                              className="form-control"
                              placeholder="0"
                              value={txtAToMin}
                              onChange={(e) =>
                                this.setState({
                                  txtAToMin: e.target.value,
                                })
                              }
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="box">
                  <div className="mt-2">
                    <div className="row align-items-center">
                      <div className="col-6 col-md-2 mb-md-1">
                        <div className="form-group mb-md-0">
                          <input
                            type="text"
                            className="form-control"
                            placeholder="Deliverysite Ean code"
                            value={txtADeliverysiteCode}
                            onChange={(e) =>
                              this.setState({
                                txtADeliverysiteCode: e.target.value,
                              })
                            }
                          />
                        </div>
                      </div>
                      <div className="col-6 col-md-2 mb-md-1">
                        <div className="form-group mb-md-0">
                          <input
                            type="text"
                            className="form-control"
                            placeholder="Ack Code"
                            value={txtAAckCode}
                            onChange={(e) =>
                              this.setState({
                                txtAAckCode: e.target.value,
                              })
                            }
                          />
                        </div>
                      </div>
                      <div className="col-6 col-md-2 mb-md-1">
                        <div className="form-group mb-md-0">
                          <input
                            type="text"
                            className="form-control"
                            placeholder="Ack Text"
                            value={txtAAckText}
                            onChange={(e) =>
                              this.setState({
                                txtAAckText: e.target.value,
                              })
                            }
                          />
                        </div>
                      </div>
                      <div className="col-6 col-md-2 mb-md-1">
                        <div className="form-group select_grp mb-md-0">
                          <select
                            className="form-control"
                            value={selASenderIndex}
                            onChange={(e) =>
                              this.setState({
                                selASenderIndex: e.target.value,
                              })
                            }
                          >
                            <option value={""}>Sending System</option>
                            {arrSystems.map((item, index) => {
                              return (
                                <option value={item.systemId}>
                                  {item.systemName}
                                </option>
                              );
                            })}
                          </select>
                          <button
                            className="select_reset"
                            onClick={() => {
                              this.setState({ selASenderIndex: "" });
                            }}
                          >
                            ×
                          </button>
                        </div>
                      </div>
                      <div className="col-6 col-md-2 mb-md-1">
                        <div className="form-group select_grp mb-md-0">
                          <select
                            className="form-control"
                            value={selAAcknowledgeIndex}
                            onChange={(e) =>
                              this.setState({
                                selAAcknowledgeIndex: e.target.value,
                              })
                            }
                          >
                            <option>Acknowledge</option>
                            {arrAcknowledge.map((item, index) => {
                              return (
                                <option value={item.listValueId}>
                                  {item.listValueName || "No Ack"}
                                </option>
                              );
                            })}
                          </select>
                          <button
                            className="select_reset"
                            onClick={() => {
                              this.setState({ selAAcknowledgeIndex: "" });
                            }}
                          >
                            ×
                          </button>
                        </div>
                      </div>
                      <div className="col-6 col-md-2 mb-md-1">
                        <div className="form-group select_grp mb-md-0">
                          <select
                            className="form-control"
                            value={selATimeIndex}
                            onChange={(e) =>
                              this.setState({
                                selATimeIndex: e.target.value,
                              })
                            }
                          >
                            <option value={""}>Resolution</option>
                            {arrTimes.map((item, index) => {
                              return (
                                <option value={item.listValueId}>
                                  {item.listValueName}
                                </option>
                              );
                            })}
                          </select>
                          <button
                            className="select_reset"
                            onClick={() => {
                              this.setState({ selATimeIndex: "" });
                            }}
                          >
                            ×
                          </button>
                        </div>
                      </div>
                      <div className="col-6 col-md-2 mb-md-1">
                        <div className="form-group select_grp mb-md-0">
                          <select
                            className="form-control"
                            value={selAReceiverIndex}
                            onChange={(e) =>
                              this.setState({
                                selAReceiverIndex: e.target.value,
                              })
                            }
                          >
                            <option value={""}>Recieving System</option>
                            {arrSystems.map((item, index) => {
                              return (
                                <option value={item.systemId}>
                                  {item.systemName}
                                </option>
                              );
                            })}
                          </select>
                          <button
                            className="select_reset"
                            onClick={() => {
                              this.setState({ selAReceiverIndex: "" });
                            }}
                          >
                            ×
                          </button>
                        </div>
                      </div>
                      <div className="col-6 col-md-2 mb-md-1">
                        <div className="form-group select_grp mb-md-0">
                          <select
                            className="form-control"
                            value={selAMessageTypeIndex}
                            onChange={(e) =>
                              this.setState({
                                selAMessageTypeIndex: e.target.value,
                              })
                            }
                          >
                            <option value={""}>Message Type</option>
                            {arrAcknowledgeMessageTypes.map((item, index) => {
                              return (
                                <option value={item.messageType}>
                                  {item.messageType}
                                </option>
                              );
                            })}
                          </select>
                          <button
                            className="select_reset"
                            onClick={() => {
                              this.setState({ selAMessageTypeIndex: "" });
                            }}
                          >
                            ×
                          </button>
                        </div>
                      </div>
                      <div className="col-6 col-md-2 mb-md-1">
                        <div className="form-group select_grp mb-md-0">
                          <select
                            className="form-control"
                            value={selAMessageCategoryIndex}
                            onChange={(e) =>
                              this.setState({
                                selAMessageCategoryIndex: e.target.value,
                              })
                            }
                          >
                            <option value={""}>Message Category</option>
                            {arrMessageCategory.map((item, index) => {
                              return (
                                <option value={item.messageCategory}>
                                  {item.messageCategory}
                                </option>
                              );
                            })}
                          </select>
                          <button
                            className="select_reset"
                            onClick={() => {
                              this.setState({ selAMessageCategoryIndex: "" });
                            }}
                          >
                            ×
                          </button>
                        </div>
                      </div>
                      <div className="col-md-2 col-lg-1  mb-md-1">
                        <button
                          className="btn btn-primary btn-block btn-sm"
                          onClick={() => this.onCallGetGraphAcknowledge()}
                        >
                          Search
                        </button>
                      </div>
                      <div className="col-md-3 mb-md-1">
                        <small>
                          <b>Number Of Results : 0</b>
                        </small>
                      </div>
                    </div>
                  </div>
                </div>

                <hr className="space" />

                <div className="box">
                  {isLoadAcknowledgeGraph ? (
                    <Loader message={"loading acknowledge graph data"} />
                  ) : null}
                  <ReactApexChart
                    options={{
                      colors: ["#e62e00", "#32a852", "#ffa52f"],
                      chart: {
                        type: "bar",
                        height: 500,
                        stacked: true,
                        toolbar: {
                          show: true,
                        },
                        zoom: {
                          enabled: true,
                        },
                      },
                      responsive: [
                        {
                          breakpoint: 480,
                          options: {
                            legend: {
                              position: "bottom",
                              offsetX: -10,
                              offsetY: 0,
                            },
                          },
                        },
                      ],
                      plotOptions: {
                        bar: {
                          horizontal: false,
                        },
                      },
                      xaxis: {
                        //type: "datetime",
                        categories: arrGraphAcknowledgeCategory || [],
                      },
                      legend: {
                        position: "right",
                        offsetY: 40,
                      },
                      fill: {
                        opacity: 1,
                      },
                    }}
                    series={arrGraphAcknowledge}
                    type="bar"
                    height={500}
                  />
                </div>
              </div>
              <div className="tab-pane" id="tab_5">
                <div className="row align-items-center mb-2">
                  <div className="col-md-6">
                    <div className="box">
                      <div className="row align-items-center">
                        <div className="col-2 col-md-1">
                          <label className="mb-0">From</label>
                        </div>
                        <div className="col-4 col-md-2">
                          <div className="date-select">
                            <input
                              type="date"
                              name="from"
                              placeholder=""
                              className="form-control date"
                              value={txtARFrom}
                              onChange={(e) =>
                                this.setState({
                                  txtARFrom: e.target.value,
                                })
                              }
                            />
                          </div>
                        </div>
                        <div className="col-2 col-md-1">
                          <label className="mb-0">Hour</label>
                        </div>
                        <div className="col-4 col-md-2">
                          <div className="form-group mb-md-0">
                            <input
                              disabled={
                                selARTimeIndex === "4" || selARTimeIndex === "5"
                                  ? false
                                  : true
                              }
                              type="number"
                              className="form-control"
                              placeholder="0"
                              value={txtARFromHour}
                              onChange={(e) =>
                                this.setState({
                                  txtARFromHour: e.target.value,
                                })
                              }
                            />
                          </div>
                        </div>
                        <div className="col-2 col-md-1">
                          <label className="mb-0">Minute</label>
                        </div>
                        <div className="col-4 col-md-2">
                          <div className="form-group mb-md-0">
                            <input
                              disabled={selARTimeIndex === "5" ? false : true}
                              type="number"
                              className="form-control"
                              placeholder="0"
                              value={txtARFromMin}
                              onChange={(e) =>
                                this.setState({
                                  txtARFromMin: e.target.value,
                                })
                              }
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="col-md-6">
                    <div className="box">
                      <div className="row align-items-center">
                        <div className="col-2 col-md-1">
                          <label className="mb-0">To</label>
                        </div>
                        <div className="col-4 col-md-2">
                          <div className="date-select">
                            <input
                              type="date"
                              name="from"
                              placeholder=""
                              className="form-control date"
                              value={txtARTo}
                              onChange={(e) =>
                                this.setState({
                                  txtARTo: e.target.value,
                                })
                              }
                            />
                          </div>
                        </div>
                        <div className="col-2 col-md-1">
                          <label className="mb-0">Hour</label>
                        </div>
                        <div className="col-4 col-md-2">
                          <div className="form-group mb-md-0">
                            <input
                              disabled={
                                selARTimeIndex === "4" || selARTimeIndex === "5"
                                  ? false
                                  : true
                              }
                              type="number"
                              className="form-control"
                              placeholder="0"
                              value={txtARToHour}
                              onChange={(e) =>
                                this.setState({
                                  txtARToHour: e.target.value,
                                })
                              }
                            />
                          </div>
                        </div>
                        <div className="col-2 col-md-1">
                          <label className="mb-0">Minute</label>
                        </div>
                        <div className="col-4 col-md-2">
                          <div className="form-group mb-md-0">
                            <input
                              disabled={selARTimeIndex === "5" ? false : true}
                              type="number"
                              className="form-control"
                              placeholder="0"
                              value={txtARToMin}
                              onChange={(e) =>
                                this.setState({
                                  txtARToMin: e.target.value,
                                })
                              }
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="box">
                  <div className="mt-2">
                    <div className="row align-items-center">
                      <div className="col-6 col-md-2 mb-md-1">
                        <div className="form-group mb-md-0">
                          <input
                            type="text"
                            className="form-control"
                            placeholder="Deliverysite Ean code"
                            value={txtARDeliverysiteCode}
                            onChange={(e) =>
                              this.setState({
                                txtARDeliverysiteCode: e.target.value,
                              })
                            }
                          />
                        </div>
                      </div>
                      <div className="col-6 col-md-2 mb-md-1">
                        <div className="form-group mb-md-0">
                          <input
                            type="text"
                            className="form-control"
                            placeholder="Ack Code"
                            value={txtARAckCode}
                            onChange={(e) =>
                              this.setState({
                                txtARAckCode: e.target.value,
                              })
                            }
                          />
                        </div>
                      </div>
                      <div className="col-6 col-md-2 mb-md-1">
                        <div className="form-group mb-md-0">
                          <input
                            type="text"
                            className="form-control"
                            placeholder="Ack Text"
                            value={txtARAckText}
                            onChange={(e) =>
                              this.setState({
                                txtARAckText: e.target.value,
                              })
                            }
                          />
                        </div>
                      </div>
                      <div className="col-6 col-md-2 mb-md-1">
                        <div className="form-group select_grp mb-md-0">
                          <select
                            className="form-control"
                            value={selARSenderIndex}
                            onChange={(e) =>
                              this.setState({
                                selARSenderIndex: e.target.value,
                              })
                            }
                          >
                            <option value={""}>Sending System</option>
                            {arrSystems.map((item, index) => {
                              return (
                                <option value={item.systemId}>
                                  {item.systemName}
                                </option>
                              );
                            })}
                          </select>
                          <button
                            className="select_reset"
                            onClick={() => {
                              this.setState({ selARSenderIndex: "" });
                            }}
                          >
                            ×
                          </button>
                        </div>
                      </div>
                      <div className="col-6 col-md-2 mb-md-1">
                        <div className="form-group select_grp mb-md-0">
                          <select
                            className="form-control"
                            value={selARAcknowledgeIndex}
                            onChange={(e) =>
                              this.setState({
                                selARAcknowledgeIndex: e.target.value,
                              })
                            }
                          >
                            <option>Acknowledge</option>
                            {arrAcknowledge.map((item, index) => {
                              return (
                                <option value={item.listValueId}>
                                  {item.listValueName || "No Ack"}
                                </option>
                              );
                            })}
                          </select>
                          <button
                            className="select_reset"
                            onClick={() => {
                              this.setState({ selARAcknowledgeIndex: "" });
                            }}
                          >
                            ×
                          </button>
                        </div>
                      </div>
                      <div className="col-6 col-md-2 mb-md-1">
                        <div className="form-group select_grp mb-md-0">
                          <select
                            className="form-control"
                            value={selARTimeIndex}
                            onChange={(e) =>
                              this.setState({
                                selARTimeIndex: e.target.value,
                              })
                            }
                          >
                            <option value={""}>Resolution</option>
                            {arrTimes.map((item, index) => {
                              return (
                                <option value={item.listValueId}>
                                  {item.listValueName}
                                </option>
                              );
                            })}
                          </select>
                          <button
                            className="select_reset"
                            onClick={() => {
                              this.setState({ selARTimeIndex: "" });
                            }}
                          >
                            ×
                          </button>
                        </div>
                      </div>
                      <div className="col-6 col-md-2 mb-md-1">
                        <div className="form-group select_grp mb-md-0">
                          <select
                            className="form-control"
                            value={selARReceiverIndex}
                            onChange={(e) =>
                              this.setState({
                                selARReceiverIndex: e.target.value,
                              })
                            }
                          >
                            <option value={""}>Recieving System</option>
                            {arrSystems.map((item, index) => {
                              return (
                                <option value={item.systemId}>
                                  {item.systemName}
                                </option>
                              );
                            })}
                          </select>
                          <button
                            className="select_reset"
                            onClick={() => {
                              this.setState({ selARReceiverIndex: "" });
                            }}
                          >
                            ×
                          </button>
                        </div>
                      </div>
                      <div className="col-6 col-md-2 mb-md-1">
                        <div className="form-group select_grp mb-md-0">
                          <select
                            className="form-control"
                            value={selARMessageTypeIndex}
                            onChange={(e) =>
                              this.setState({
                                selARMessageTypeIndex: e.target.value,
                              })
                            }
                          >
                            <option value={""}>Message Type</option>
                            {arrAcknowledgeMessageTypes.map((item, index) => {
                              return (
                                <option value={item.messageType}>
                                  {item.messageType}
                                </option>
                              );
                            })}
                          </select>
                          <button
                            className="select_reset"
                            onClick={() => {
                              this.setState({ selARMessageTypeIndex: "" });
                            }}
                          >
                            ×
                          </button>
                        </div>
                      </div>
                      <div className="col-6 col-md-2 mb-md-1">
                        <div className="form-group select_grp mb-md-0">
                          <select
                            className="form-control"
                            value={selARMessageCategoryIndex}
                            onChange={(e) =>
                              this.setState({
                                selARMessageCategoryIndex: e.target.value,
                              })
                            }
                          >
                            <option value={""}>Message Category</option>
                            {arrMessageCategory.map((item, index) => {
                              return (
                                <option value={item.messageCategory}>
                                  {item.messageCategory}
                                </option>
                              );
                            })}
                          </select>
                          <button
                            className="select_reset"
                            onClick={() => {
                              this.setState({ selARMessageCategoryIndex: "" });
                            }}
                          >
                            ×
                          </button>
                        </div>
                      </div>
                      <div className="col-md-2 col-lg-1  mb-md-1">
                        <button
                          className="btn btn-primary btn-block btn-sm"
                          onClick={() => this.onCallGetGraphAcknowledgeRow()}
                        >
                          Search
                        </button>
                      </div>
                      <div className="col-md-3 mb-md-1">
                        <small>
                          <b>Number Of Results : 0</b>
                        </small>
                      </div>
                    </div>
                  </div>
                </div>

                <hr className="space" />

                <div className="box">
                  {isLoadAcknowledgeRowGraph ? (
                    <Loader message={"loading acknowledge row graph data"} />
                  ) : null}
                  <ReactApexChart
                    options={{
                      colors: ["#e62e00", "#32a852", "#ffa52f"],
                      chart: {
                        type: "bar",
                        height: 500,
                        stacked: true,
                        toolbar: {
                          show: true,
                        },
                        zoom: {
                          enabled: true,
                        },
                      },
                      responsive: [
                        {
                          breakpoint: 480,
                          options: {
                            legend: {
                              position: "bottom",
                              offsetX: -10,
                              offsetY: 0,
                            },
                          },
                        },
                      ],
                      plotOptions: {
                        bar: {
                          horizontal: false,
                        },
                      },
                      xaxis: {
                        //type: "datetime",
                        categories: arrGraphAcknowledgeRowCategory || [],
                      },
                      legend: {
                        position: "right",
                        offsetY: 40,
                      },
                      fill: {
                        opacity: 1,
                      },
                    }}
                    series={arrGraphAcknowledgeRow || []}
                    type="bar"
                    height={500}
                  />
                </div>
              </div>
            </div>
          </div>
          <MessageDetail
            messageBinary={this.state.messageBinary}
            messageRaw={this.state.messageRaw}
            show={isMessageModal}
            handleClose={() => this.setState({ isMessageModal: false })}
          />
        </Container>
      </>
    );
  }
}

const mapStateToProps = (state) => {
  const { user, isLogin } = state.user;
  return {
    user,
    isLogin,
  };
};

export default connect(mapStateToProps, {
  getSystems,
  getMessages,
  getMessageRaw,
  getMessageBinary,
  getMessagesRows,
  getMessageValidation,
  getMessageTypeList,
  getSearchTypeList,
  getMessageAcknowledgeList,
  getMessageCategories,
  getWatchdogCategory,
  getResolutions,
  getGraphMessages,
  getGroupProcess,
  getProcessByID,
  getGraphAcknowledge,
  getGraphAcknowledgeRow,
  getAcknowledgeMessage,
  getAcknowledgeMessageTypeList,
  getMessageAcknowledgeRows,
  getWatchdogDataList,
})(Message);
