import React from "react";

import iconXLS from "../../img/ic_xls.png";

import { connect } from "react-redux";
import { AgGridReact } from "ag-grid-react";
import "@ag-grid-community/core/dist/styles/ag-grid.css";
import "@ag-grid-community/core/dist/styles/ag-theme-alpine.css";

import { getSQLQueryData } from "../../redux/action/sqlAction";
import exportFromJSON from "export-from-json";

import Sidemenu from "../drawer/Sidemenu";
import Container from "../layout/Container";
import Loader from "../layout/Loader";

class Sql extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      txtQuery: "",
      isLoadSQL: false,
      sqlRows: [],
      sqlColumn: [],
      sqlData: [],
    };
  }
  componentDidMount() {}

  //API Call Methods
  onCallGetExecuteSQL = () => {
    const { txtQuery } = this.state;
    let params = txtQuery;
    this.setState({ isLoadSQL: true });
    this.props
      .getSQLQueryData(params)
      .then(async (response) => {
        this.setState({ isLoadSQL: false });
        console.log("SQL response: ", response);
        this.manageSQL(
          response.data.columnNames || [],
          response.data.data || []
        );
      })
      .catch((err) => {
        this.setState({ isLoadSQL: false });
        console.log("SQL error: ", err);
      });
  };

  //Data Methods
  manageSQL = (columns, rows) => {
    let arrSQL = [];
    for (let index = 0; index < rows.length; index++) {
      let obj = rows[index].dataRow;
      let dict = {};
      for (let index2 = 0; index2 < columns.length; index2++) {
        let obj2 = columns[index2];
        dict[obj2] = obj[index2];
      }
      arrSQL.push(dict);
    }
    let arrSQLColumn = [];
    for (const obj of columns) {
      let dict = { field: obj };
      arrSQLColumn.push(dict);
    }
    this.setState({ sqlRows: arrSQL, sqlColumn: arrSQLColumn });
  };

  //Export Methods
  exportToXLS = (data, fileName) => {
    const exportType = "xls";
    exportFromJSON({ data, fileName, exportType });
  };

  //Render Methods
  render() {
    const { isLoadSQL, txtQuery, sqlColumn, sqlRows } = this.state;
    return (
      <Container>
        <Sidemenu selIndex={14} />
        <div className="content" style={styles.contaienr}>
          <div class="title">
            <h2>SQL Runner</h2>
          </div>
          <textarea
            style={styles.txtQuery}
            value={txtQuery}
            onChange={(e) => this.setState({ txtQuery: e.target.value })}
          />
          <div style={{ flexDirection: "row", marginTop: 10 }}>
            <button
              disabled={txtQuery.trim().length > 0 ? false : true}
              style={{ width: 100 }}
              className="btn btn-primary btn-sm"
              onClick={() => this.onCallGetExecuteSQL()}
            >
              Run SQL
            </button>
            <button
              style={{ width: 100 }}
              className="btn btn-primary btn-sm"
              onClick={() =>
                this.setState({ sqlRows: [], sqlColumn: [], txtQuery: "" })
              }
            >
              Clear
            </button>
          </div>
          <div style={{ marginTop: 8 }}>
            <b>Number Of Results : {sqlRows.length}</b>
          </div>

          <div className="box" style={{ marginTop: 12 }}>
            <div className="table-responsive">
              {/* Download XLS */}
              <div
                style={{
                  display: "flex",
                  justifyContent: "flex-end",
                }}
              >
                <img
                  src={iconXLS}
                  style={{ width: 20, height: 20, marginBottom: 8 }}
                  alt="export_xls"
                  title={"Export XLS"}
                  onClick={() => this.exportToXLS(sqlRows, "sql_data")}
                />
              </div>
              {isLoadSQL ? <Loader message={"loading query data"} /> : null}
              <div
                className="ag-theme-alpine"
                style={{ height: 500, width: "100%", fontSize: 12 }}
              >
                <AgGridReact
                  enableCellTextSelection={true}
                  pagination={true}
                  paginationAutoPageSize={true}
                  rowSelection={"single"}
                  rowData={sqlRows}
                  columnDefs={sqlColumn}
                  defaultColDef={{ resizable: true, sortable: true }}
                  onSelectionChanged={(e) => {}}
                ></AgGridReact>
              </div>
            </div>
          </div>
        </div>
      </Container>
    );
  }
}

const styles = {
  contaienr: {
    display: "flex",
    flexDirection: "column",
  },
  label: {
    fontSize: 20,
    fontWeight: "600",
  },
  txtQuery: {
    width: "100%",
    height: "22%",
    borderRadius: 6,
    padding: 8,
    borderColor: "lightGray",
    borderWidth: 1,
  },
};

const mapStateToProps = (state) => {
  const { user, isLogin } = state.user;
  return {
    user,
    isLogin,
  };
};

export default connect(mapStateToProps, {
  getSQLQueryData,
})(Sql);
