import React, { Component } from "react";

import { AgGridReact } from "ag-grid-react";
import { connect } from "react-redux";

import "@ag-grid-community/core/dist/styles/ag-grid.css";
import "@ag-grid-community/core/dist/styles/ag-theme-alpine.css";

import {
  getWatchdogCategory,
  getWatchdogList,
} from "../../redux/action/watchdogAction";

import Sidemenu from "../drawer/Sidemenu";
import Container from "../layout/Container";
import Loader from "../layout/Loader";
import Sql from "./subComponent/Sql";

class Watchdogs extends Component {
  constructor(props) {
    super(props);
    this.state = {
      selQuery: "",
      isSqlModal: false,
      isLoadCategory: false,
      isLoadWatchdogList: false,
      category: [],
      watchdog: [],
    };
  }

  componentDidMount() {
    this.onCallGetWatchdogsCategory();
  }

  //Button Click Events
  onClick = () => {};

  //API Call Methods
  onCallGetWatchdogsCategory = () => {
    let params = {};
    this.setState({ isLoadCategory: true });
    this.props
      .getWatchdogCategory(params)
      .then(async (response) => {
        this.setState({ isLoadCategory: false });
        console.log("Watchdog Category response: ", response);
        this.manageCategory(response.data || []);
      })
      .catch((err) => {
        this.setState({ isLoadCategory: false });
        console.log("Watchdog Category error: ", err);
      });
  };
  onCallGetWatchdogsList = (id) => {
    let params = {};
    params.id = id;
    this.setState({ isLoadWatchdogList: true });
    this.props
      .getWatchdogList(params)
      .then(async (response) => {
        this.setState({ isLoadWatchdogList: false });
        console.log("Watchdog Category response: ", response);
        this.manageWatchdogList(response.data || []);
      })
      .catch((err) => {
        this.setState({ isLoadWatchdogList: false });
        console.log("Watchdog Category error: ", err);
      });
  };

  //Data Methods
  manageCategory = (data) => {
    let arrCategory = [];
    for (const obj of data) {
      let dict = {};
      dict.no = obj.warningCaseTypeId;
      dict.groupName = obj.warningCaseTypeName;
      arrCategory.push(dict);
    }
    this.setState({ category: arrCategory });
  };
  manageWatchdogList = (data) => {
    let arrWatchdogs = [];
    for (const obj of data) {
      let dict = {};
      dict._ =
        obj.actualCount > obj.expectedMaxCounter ||
        obj.actualCount > obj.exptectedMinCounter
          ? "warning"
          : "correct";
      dict.id = obj.warningCaseTypeId;
      dict.warningName = obj.WarningCaseName;
      dict.warningDescription = obj.WarningCaseDesc;
      dict.actualCounter = obj.actualCount;
      dict.maxCounter = obj.expectedMaxCounter;
      dict.minCounter = obj.exptectedMinCounter;
      dict.statisticsTime = obj.statisticDateTime;
      dict.sql = "🔍";
      dict.query = obj.sqlToRun;
      arrWatchdogs.push(dict);
    }
    this.setState({ watchdog: arrWatchdogs });
  };

  render() {
    const arrCategory = Object.assign([], this.state.category);
    const arrWatchdogs = Object.assign([], this.state.watchdog);
    const {
      isLoadCategory,
      isLoadWatchdogList,
      selQuery,
      isSqlModal,
    } = this.state;

    return (
      <Container>
        <Sidemenu selIndex={1} />
        <div className="content">
          <div className="tab-pane active" id="inner_1">
            <div className="title">
              <h2>Watch Dogs</h2>
            </div>
            <div className="box">
              <div className="list-group min-tab">
                <a
                  className="list-group-item"
                  data-toggle="list"
                  href="#dashboard"
                >
                  Dashboard
                </a>
                <a className="list-group-item active" data-toggle="list" href="#all">
                  All
                </a>
              </div>
              <div className="tab-content">
                <div className="tab-pane" id="dashboard">
                  <div className="table-responsive"></div>
                </div>
                <div className="tab-pane active" id="all">
                  <div className="table-responsive">
                    {isLoadCategory ? (
                      <Loader message={"loading watchdogs categories"} />
                    ) : null}
                    <div
                      className="ag-theme-alpine"
                      style={{ height: 280, width: "100%", fontSize: 12 }}
                    >
                      <AgGridReact
                        enableCellTextSelection={true}
                        pagination={true}
                        paginationAutoPageSize={true}
                        rowSelection={"single"}
                        rowData={arrCategory || []}
                        defaultColDef={{
                          resizable: true,
                          suppressSizeToFit: true,
                        }}
                        columnDefs={[
                          { field: "no", width: 60 },
                          { field: "groupName" },
                        ]}
                        onCellClicked={(e) => {
                          console.log("cell: ", e);
                        }}
                        onSelectionChanged={(e) => {
                          console.log("row: ", e.api.getSelectedRows()[0]);
                          let selectedRow = e.api.getSelectedRows()[0];
                          this.onCallGetWatchdogsList(selectedRow.no);
                        }}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <hr className="space" />
            <div className="title">
              <h2>Not Acked Messages</h2>
            </div>
            <div className="box">
              <div className="list-group min-tab">
                <a
                  className="list-group-item active"
                  data-toggle="list"
                  href="#current"
                >
                  Current
                </a>
              </div>
              <div className="tab-content">
                <div className="tab-pane active" id="current">
                  <div className="table-responsive">
                    {isLoadWatchdogList ? (
                      <Loader message={"loading watchdogs list"} />
                    ) : null}
                    <div
                      className="ag-theme-alpine"
                      style={{ height: 420, width: "100%", fontSize: 12 }}
                    >
                      <AgGridReact
                        enableCellTextSelection={true}
                        pagination={true}
                        paginationAutoPageSize={true}
                        rowSelection={"single"}
                        rowData={arrWatchdogs || []}
                        defaultColDef={{
                          resizable: true,
                          suppressSizeToFit: true,
                        }}
                        columnDefs={[
                          {
                            field: "_",
                            width: 50,
                            minWidth: 50,
                            maxWidth: 50,
                            cellRenderer: (params) => {
                              let roundColor = "";
                              if (params.data._ === "warning")
                                roundColor =
                                  '<i class="fa fa-exclamation-triangle" style="color:#ffcc00"></i>';
                              if (params.data._ === "correct")
                                roundColor =
                                  '<i class="fa fa-check" style="color:#339900"></i>';
                              return roundColor;
                            },
                          },
                          { field: "no", width: 60 },
                          { field: "warningName" },
                          { field: "warningDescription" },
                          { field: "actualCounter", width: 150 },
                          { field: "maxCounter", width: 150 },
                          { field: "minCounter", width: 150 },
                          { field: "statisticsTime" },
                          { field: "sql", width: 60 },
                        ]}
                        onCellClicked={(e) => {
                          console.log("cell: ", e);
                          if (
                            e.value === "🔍" &&
                            e.column.getColId() === "sql"
                          ) {
                            this.setState({ selQuery: e.data.query }, () => {
                              this.setState({ isSqlModal: true });
                            });
                          }
                        }}
                        onSelectionChanged={(e) => {
                          console.log("row: ", e.api.getSelectedRows()[0]);
                        }}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <Sql
          query={selQuery || ""}
          show={isSqlModal}
          handleClose={() => this.setState({ isSqlModal: false })}
        />
      </Container>
    );
  }
}

const mapStateToProps = (state) => {
  const { user, isLogin } = state.user;
  return {
    user,
    isLogin,
  };
};

export default connect(mapStateToProps, {
  getWatchdogCategory,
  getWatchdogList,
})(Watchdogs);
