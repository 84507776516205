import { makeRequest } from "../../api/apiCall";
import {
  messages,
  rows,
  binaryInfo,
  raw,
  messageAcknowledgeRow,
} from "../../api/apiConstant";

//Message Actions
export const getMessages = (userData) => {
  return (dispatch, getState) => {
    try {
      return makeRequest(messages, "get", userData)
        .then((response) => {
          console.log("Message Data: " + response.data);
          return Promise.resolve({
            status: true,
            data: response.data || [],
            message: "",
          });
        })
        .catch((error) => {
          console.log("Error: ", error);
          return Promise.reject(error);
        });
    } catch (e) {
      return Promise.reject(e);
    }
  };
};

export const getMessagesRows = (userData) => {
  return (dispatch, getState) => {
    try {
      return makeRequest(messages + "/" + userData.id + "/" + rows, "get", {})
        .then((response) => {
          console.log("Message Rows Data: " + response.data);
          return Promise.resolve({
            status: true,
            data: response.data || [],
            message: "",
          });
        })
        .catch((error) => {
          console.log("Error: ", error);
          return Promise.reject(error);
        });
    } catch (e) {
      return Promise.reject(e);
    }
  };
};

export const getMessageAcknowledgeRows = (userData) => {
  return (dispatch, getState) => {
    try {
      return makeRequest(messageAcknowledgeRow, "get", userData)
        .then((response) => {
          console.log("Message Rows Data: " + response.data);
          return Promise.resolve({
            status: true,
            data: response.data || [],
            message: "",
          });
        })
        .catch((error) => {
          console.log("Error: ", error);
          return Promise.reject(error);
        });
    } catch (e) {
      return Promise.reject(e);
    }
  };
};

export const getMessageRaw = (userData) => {
  return (dispatch, getState) => {
    try {
      return makeRequest(
        messages + "/" + userData.id + "/" + raw,
        "get",
        userData
      )
        .then((response) => {
          console.log("Message Raw Data: " + response.data);
          return Promise.resolve({
            status: true,
            data: response.data,
            message: "",
          });
        })
        .catch((error) => {
          console.log("Error: ", error);
          return Promise.reject(error);
        });
    } catch (e) {
      return Promise.reject(e);
    }
  };
};

export const getMessageDetail = (userData) => {
  return (dispatch, getState) => {
    try {
      return makeRequest(messages + "/" + userData.id, "get", userData)
        .then((response) => {
          console.log("Message Data: " + response.data);
          return Promise.resolve({
            status: true,
            data: response.data || [],
            message: "",
          });
        })
        .catch((error) => {
          console.log("Error: ", error);
          return Promise.reject(error);
        });
    } catch (e) {
      return Promise.reject(e);
    }
  };
};

export const getMessageBinary = (userData) => {
  return (dispatch, getState) => {
    try {
      return makeRequest(
        messages + "/" + userData.id + "/" + binaryInfo,
        "get",
        userData
      )
        .then((response) => {
          console.log("Message Binary Data: " + response.data);
          return Promise.resolve({
            status: true,
            data: response.data || {},
            message: "",
          });
        })
        .catch((error) => {
          console.log("Error: ", error);
          return Promise.reject(error);
        });
    } catch (e) {
      return Promise.reject(e);
    }
  };
};
