import React, { Component } from "react";

import { AgGridReact } from "ag-grid-react";
import { connect } from "react-redux";

import "@ag-grid-community/core/dist/styles/ag-grid.css";
import "@ag-grid-community/core/dist/styles/ag-theme-alpine.css";

import {
  getGroupProcess,
  getProcessByID,
} from "../../redux/action/processAction";

import iconArrow from "../../img/ic_arrow.png";
import Sidemenu from "../drawer/Sidemenu";
import Container from "../layout/Container";
import Loader from "../layout/Loader";
import Sql from "./subComponent/Sql";

class ProcessScenario extends Component {
  constructor(props) {
    super(props);
    this.state = {
      selQuery: "",
      process: [],
      processList: [],
      isSqlModal: false,
      isLoadProcess: false,
      isLoadProcessList: false,
      selProcess: {},
    };
  }

  componentDidMount() {
    this.onCallGetProcess();
  }

  //Button Click Events
  onClick = () => {};

  //API Call Methods
  onCallGetProcess = () => {
    let params = {};
    this.setState({ isLoadProcess: true });
    this.props
      .getGroupProcess(params)
      .then(async (response) => {
        this.setState({ isLoadProcess: false });
        console.log("Process response: ", response);
        this.manageProcess(response.data || []);
      })
      .catch((err) => {
        this.setState({ isLoadProcess: false });
        console.log("Process error: ", err);
      });
  };
  onCallGetProcessList = (id) => {
    let params = {};
    params.id = id;
    this.setState({ isLoadProcessList: true });
    this.props
      .getProcessByID(params)
      .then(async (response) => {
        this.setState({ isLoadProcessList: false });
        console.log("Process response: ", response);
        this.manageProcessList(response.data || []);
      })
      .catch((err) => {
        this.setState({ isLoadProcessList: false });
        console.log("Process error: ", err);
      });
  };

  //Data Methods
  manageProcess = (data) => {
    let arrProcess = [];
    for (const obj of data) {
      let dict = {};
      dict.id = obj.testCaseId;
      dict.processName = obj.testCaseName;
      dict.active = obj.testCaseActive === true ? "true" : "false";
      arrProcess.push(dict);
    }
    this.setState({ process: arrProcess });
  };
  manageProcessList = (data) => {
    let arrProcessList = [];
    for (const obj of data) {
      let dict = {};
      dict.processOrderId = obj.TestCaseProcessOrderId;
      dict.messageTypeId = obj.MessageTypeId;
      dict.messageType = obj.MessageType;
      dict.expectedCounter = obj.ExpecteMessageCounter;
      dict.counter = obj.MessageCounter;
      dict.expectedValueCounter = obj.ExpectedValueCounter;
      dict.valueCounter = obj.ValueCounter;
      dict.statisticTime = obj.statisticDateTime;
      dict.sql = "🔍";
      dict.query = obj.sqlToRun;
      arrProcessList.push(dict);
    }
    this.setState({ processList: arrProcessList });
  };

  render() {
    const arrProcess = Object.assign([], this.state.process);
    const arrProcessList = Object.assign([], this.state.processList);
    const {
      isLoadProcess,
      isLoadProcessList,
      selProcess,
      isSqlModal,
      selQuery,
    } = this.state;

    return (
      <Container>
        <Sidemenu selIndex={4} />
        <div className="content">
          <div class="title">
            <h2>Process Scenarios</h2>
          </div>
          <div class="box">
            <div class="tab-content">
              <div class="tab-pane active" id="dashboard">
                <div class="table-responsive">
                  {isLoadProcess ? (
                    <Loader message={"loading process"} />
                  ) : null}
                  <div
                    className="ag-theme-alpine"
                    style={{ height: 280, width: "100%", fontSize: 12 }}
                  >
                    <AgGridReact
                      enableCellTextSelection={true}
                      pagination={true}
                      paginationAutoPageSize={true}
                      rowSelection={"single"}
                      rowData={arrProcess || []}
                      defaultColDef={{
                        resizable: true,
                        suppressSizeToFit: true,
                      }}
                      columnDefs={[
                        { field: "id", width: 60 },
                        { field: "processName", flex: 1 },
                        { field: "active" },
                      ]}
                      onCellClicked={(e) => {
                        console.log("cell: ", e);
                      }}
                      onSelectionChanged={(e) => {
                        console.log("row: ", e.api.getSelectedRows()[0]);
                        let selectedRow = e.api.getSelectedRows()[0];
                        this.setState({ selProcess: selectedRow }, () => {
                          this.onCallGetProcessList(selectedRow.id);
                        });
                      }}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>

          <hr class="space" />
          {selProcess.processName ? (
            <>
              <div class="title">
                <h2>{selProcess.processName || ""}</h2>
                {/* <i
                  style={{ height: 22, width: 22 }}
                  className="fa fa-retweet"
                  aria-hidden="true"
                  onClick={() => {
                    this.onCallGetProcessList(selProcess.id);
                  }}
                ></i> */}
              </div>
            </>
          ) : null}

          <div class="box">
            <div class="list-group min-tab">
              <a
                class="list-group-item active"
                data-toggle="list"
                href="#visual_view"
              >
                Visual View
              </a>
              <a class="list-group-item" data-toggle="list" href="#list_view">
                List View
              </a>
            </div>
            <div class="tab-content">
              <div class="tab-pane active" id="visual_view">
                <div class="row align-items-center" style={styles.container}>
                  <div class="arrow-steps clearfix">
                    {arrProcessList.map((item, index) => {
                      return (
                        <div class="step">
                          <span>
                            {item.messageType} →{" "}
                            <span class="counter">
                              Counter: {item.counter || 0}
                            </span>
                          </span>
                        </div>
                      );
                    })}
                  </div>
                </div>
              </div>
              <div class="tab-pane" id="list_view">
                <div class="table-responsive">
                  {isLoadProcessList ? (
                    <Loader message={"loading process list"} />
                  ) : null}
                  <div
                    className="ag-theme-alpine"
                    style={{ height: 420, width: "100%", fontSize: 12 }}
                  >
                    <AgGridReact
                      enableCellTextSelection={true}
                      pagination={true}
                      paginationAutoPageSize={true}
                      rowSelection={"single"}
                      rowData={arrProcessList || []}
                      defaultColDef={{
                        resizable: true,
                      }}
                      columnDefs={[
                        { field: "processOrderId" },
                        { field: "messageTypeId" },
                        { field: "messageType" },
                        { field: "expectedCounter" },
                        { field: "counter" },
                        { field: "expectedValueCounter" },
                        { field: "valueCounter" },
                        { field: "statisticTime" },
                        { field: "sql" },
                      ]}
                      onCellClicked={(e) => {
                        console.log("cell: ", e);
                        if (e.value === "🔍" && e.column.getColId() === "sql") {
                          this.setState({ selQuery: e.data.query }, () => {
                            this.setState({ isSqlModal: true });
                          });
                        }
                      }}
                      onSelectionChanged={(e) => {
                        console.log("row: ", e.api.getSelectedRows()[0]);
                        let selectedRow = e.api.getSelectedRows()[0];
                        // this.onCallGetProcessList(selectedRow.id);
                      }}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <Sql
          query={selQuery || ""}
          show={isSqlModal}
          handleClose={() => this.setState({ isSqlModal: false })}
        />
      </Container>
    );
  }
}

const styles = {
  container: {
    flex: 1,
    padding: 16,
  },
  arrowContainer: {
    paddingBottom: 12,
    width: 220,
    height: 130,
  },
  image: {
    width: "100%",
    height: "100%",
    tintColor: "white",
  },
};

const mapStateToProps = (state) => {
  const { user, isLogin } = state.user;
  return {
    user,
    isLogin,
  };
};

export default connect(mapStateToProps, {
  getGroupProcess,
  getProcessByID,
})(ProcessScenario);
