import React, { useEffect, useState } from "react";

import Sidemenu from "../drawer/Sidemenu";
import Container from "../layout/Container";

const getQueryParams = (t) => {
  if (!t || "string" != typeof t || t.length < 2) return new Map();
  const r = t
    .substr(1)
    .split("&")
    .map((t) => {
      const r = t.split("=");
      return [r[0], r[1]];
    });
  return new Map(r);
};

const Home = (props) => {
  const [mode, setMode] = useState("");
  console.log("props: ", props);

  useEffect(() => {
    let queryParam = getQueryParams(props.location.search);
    if (queryParam.get("mode") && queryParam.get("mode").length > 0)
      setMode(queryParam.get("mode"));
  }, []);

  return (
    <Container>
      <Sidemenu selIndex={0} />
      <div className="content" style={styles.topContaienr}>
        <div style={styles.label}>{`Environment: ${mode}`}</div>
        <div className="content" style={styles.contaienr}>
          <div style={styles.label}>
            This screen is under development and it’ll be coming soon
          </div>
        </div>
      </div>
    </Container>
  );
};

const styles = {
  topContaienr: {},
  contaienr: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  label: {
    fontSize: 20,
    fontWeight: "600",
  },
};

export default Home;
