import { makeRequest } from "../../api/apiCall";
import { activeProcess, messageEndpoint } from "../../api/apiConstant";

//Admin Actions
export const getActiveProcess = (userData) => {
  return (dispatch, getState) => {
    try {
      return makeRequest(activeProcess, "get", userData)
        .then((response) => {
          console.log("Active Process Data: " + response.data);
          return Promise.resolve({
            status: true,
            data: response.data || [],
            message: "",
          });
        })
        .catch((error) => {
          console.log("Error: ", error);
          return Promise.reject(error);
        });
    } catch (e) {
      return Promise.reject(e);
    }
  };
};

export const getMessageEndpoint = (userData) => {
  return (dispatch, getState) => {
    try {
      return makeRequest(messageEndpoint, "get", userData)
        .then((response) => {
          console.log("Message Endpoint Data: " + response.data);
          return Promise.resolve({
            status: true,
            data: response.data || [],
            message: "",
          });
        })
        .catch((error) => {
          console.log("Error: ", error);
          return Promise.reject(error);
        });
    } catch (e) {
      return Promise.reject(e);
    }
  };
};