import React from "react";
import { Modal } from "react-bootstrap";

const MessageDetail = (props) => {
  return (
    <Modal show={props.show} onHide={props.handleClose}>
      {/* <div class="modal-dialog"> */}
      <Modal.Body>
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title">
              {props.messageBinary.binaryObjectName || ""}
            </h5>
            <button
              type="button"
              class="close"
              data-dismiss="modal"
              aria-label="Close"
              onClick={props.handleClose}
            >
              {" "}
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div class="modal-body">
            <ul class="nav setting-tab nav-tabs">
              <li class="nav-item active">
                {" "}
                <a class="nav-link active" data-toggle="tab" href="#panal-11">
                  Message Details{" "}
                </a>
              </li>
              <li class="nav-item">
                {" "}
                <a class="nav-link" data-toggle="tab" href="#panal-12">
                  Raw Message
                </a>
              </li>
            </ul>
            <div class="tab-content">
              <div class="tab-pane fade active show" id="panal-11">
                <div class="row">
                  <div class="col-lg-12 col-sm-12 col-md-12">
                    <div class="data-box mt-4">
                      <ul class="list-group custom-list border-bottom list-group-flush">
                        <li class="list-group-item">
                          <strong>Binary ObjectName: </strong>{" "}
                          {props.messageBinary.binaryObjectName || ""}
                        </li>
                        <li class="list-group-item">
                          <strong>Binary ObjectPath: </strong>{" "}
                          {props.messageBinary.binaryObjectPath || ""}
                        </li>
                        <li class="list-group-item">
                          <strong>Size: </strong> 0
                        </li>
                      </ul>
                      <h4 class="in-title">Files</h4>
                      <ul class="list-group custom-list list-group-flush mt-2">
                        <li class="list-group-item">
                          <strong>Absolute File: </strong> {""}
                        </li>
                        <li class="list-group-item">
                          <strong>Total Space: </strong> {""}
                        </li>
                        <li class="list-group-item">
                          <strong>Canonical Files: </strong>
                        </li>
                        <li class="list-group-item">
                          <strong>Parent: </strong>
                        </li>
                        <li class="list-group-item">
                          <strong>Absolute Path: </strong>
                        </li>
                        <li class="list-group-item">
                          <strong>Free Space: </strong>
                        </li>
                        <li class="list-group-item">
                          <div class="with-input">
                            <strong>Absolute:</strong>
                            {/* <input
                              class="form-control"
                              type="checkbox"
                              name=""
                            /> */}
                          </div>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
              <div class="tab-pane fade" id="panal-12">
                <div class="row">
                  <div class="col-lg-12 col-sm-12 col-md-12">
                    <div class="data-box mt-4">{props.messageRaw || ""}</div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Modal.Body>
      {/* </div> */}

      {/* <Modal.Header closeButton>
        <Modal.Title>Modal heading</Modal.Title>
      </Modal.Header>
      <Modal.Body>Woohoo, you're reading this text in a modal!</Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={props.handleClose}>
          Close
        </Button>
        <Button variant="primary" onClick={props.handleClose}>
          Save Changes
        </Button>
      </Modal.Footer> */}
    </Modal>
  );
};

export default MessageDetail;
