import React, { useEffect } from "react";

import Sidemenu from "../drawer/Sidemenu";
import Container from "../layout/Container";

const Errands = () => {
  useEffect(() => {}, []);

  return (
    <Container>
      <Sidemenu selIndex={11} />
      <div className="content" style={styles.contaienr}>
        <div style={styles.label}>
          This screen is under development and it’ll be coming soon
        </div>
      </div>
    </Container>
  );
};

const styles = {
  contaienr: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  label: {
    fontSize: 20,
    fontWeight: "600",
  },
};

export default Errands;
