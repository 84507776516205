import { makeRequest } from "../../api/apiCall";
import {
  acknowledegeMessageTypeList,
  messageAckAcknowledgeList,
  messageAcknowledge,
} from "../../api/apiConstant";

//Message Actions
export const getAcknowledgeMessage = (userData) => {
  return (dispatch, getState) => {
    try {
      return makeRequest(messageAcknowledge, "get", userData)
        .then((response) => {
          console.log("Message Acknowledge Data: " + response.data);
          return Promise.resolve({
            status: true,
            data: response.data || [],
            message: "",
          });
        })
        .catch((error) => {
          console.log("Error: ", error);
          return Promise.reject(error);
        });
    } catch (e) {
      return Promise.reject(e);
    }
  };
};

export const getAcknowledgeMessageTypeList = (userData) => {
  return (dispatch, getState) => {
    try {
      return makeRequest(acknowledegeMessageTypeList, "get", userData)
        .then((response) => {
          console.log("Acknowledge Message Type Data: " + response.data);
          return Promise.resolve({
            status: true,
            data: response.data || [],
            message: "",
          });
        })
        .catch((error) => {
          console.log("Error: ", error);
          return Promise.reject(error);
        });
    } catch (e) {
      return Promise.reject(e);
    }
  };
};
