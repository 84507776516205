import React, { Component } from "react";

import { AgGridReact } from "ag-grid-react";
import { connect } from "react-redux";

import "@ag-grid-community/core/dist/styles/ag-grid.css";
import "@ag-grid-community/core/dist/styles/ag-theme-alpine.css";

import { getMessagePerfomance } from "../../redux/action/perfomanceAction";

import Sidemenu from "../drawer/Sidemenu";
import Container from "../layout/Container";
import Loader from "../layout/Loader";

class Perfomance extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isLoadPerfomance: false,
      perfomance: [],
    };
  }

  componentDidMount() {
    this.onCallGetMessagePerfomance();
  }

  //Button Click Events
  onClick = () => {};

  //API Call Methods
  onCallGetMessagePerfomance = () => {
    let params = {};
    this.setState({ isLoadPerfomance: true });
    this.props
      .getMessagePerfomance(params)
      .then(async (response) => {
        this.setState({ isLoadPerfomance: false });
        console.log("Message Perfomance response: ", response);
        this.managePerfomance(response.data || []);
      })
      .catch((err) => {
        this.setState({ isLoadPerfomance: false });
        console.log("Message Perfomance error: ", err);
      });
  };

  //Data Methods
  managePerfomance = (data) => {
    let arrPerfomance = [];
    for (const obj of data) {
      let dict = {};
      dict.message = obj.messageType;
      dict.totalCount = obj.totalCounter > 0 ? obj.totalCounter : null;
      dict.totalCountACK =
        obj.totalCounterAck > 0
          ? `${obj.totalCounterAck} (${(
              (obj.totalCounterAck / obj.totalCounter) *
              100
            ).toFixed(2)}%)`
          : null;
      dict.totalCountNACK =
        obj.totalCounterNack > 0
          ? `${obj.totalCounterNack} (${(
              (obj.totalCounterNack / obj.totalCounter) *
              100
            ).toFixed(2)}%)`
          : null;
      dict.minSpeedACK = obj.minSpeedAck > 0 ? obj.minSpeedAck : null;
      dict.avgSpeedACK = obj.avgSpeedAck > 0 ? obj.avgSpeedAck : null;
      dict.maxSpeedACK = obj.maxSpeedAck > 0 ? obj.maxSpeedAck : null;
      dict.minSpeedIntegration =
        obj.minSpeedIntegration > 0 ? obj.minSpeedIntegration : null;
      dict.avgSpeedIntegration =
        obj.avgSpeedIntegration > 0 ? obj.avgSpeedIntegration : null;
      dict.maxSpeedIntegration =
        obj.maxSpeedIntegration > 0 ? obj.maxSpeedIntegration : null;
      dict.minSpeedRespond =
        obj.minSpeedRespond > 0 ? obj.minSpeedRespond : null;
      dict.avgSpeedRespond =
        obj.avgSpeedRespond > 0 ? obj.avgSpeedRespond : null;
      dict.maxSpeedRespond =
        obj.maxSpeedRespond > 0 ? obj.maxSpeedRespond : null;
      dict.statTime = obj.statisticDateTime;
      arrPerfomance.push(dict);
    }
    this.setState({ perfomance: arrPerfomance });
  };

  render() {
    const arrPerfomance = Object.assign([], this.state.perfomance);
    const { isLoadPerfomance } = this.state;

    return (
      <Container>
        <Sidemenu selIndex={2} />
        <div className="content">
          <div class="title">
            <h2>Message Performance</h2>
          </div>
          <div class="box">
            <div class="list-group min-tab">
              <a
                class="list-group-item active"
                data-toggle="list"
                href="#today"
              >
                Today
              </a>
              <a class="list-group-item" data-toggle="list" href="#history">
                History
              </a>
            </div>
            <div class="tab-content">
              <div class="tab-pane active" id="today">
                <div class="table-responsive">
                  {isLoadPerfomance ? (
                    <Loader message={"loading message performance"} />
                  ) : null}
                  <div
                    className="ag-theme-alpine"
                    style={{ height: 800, width: "100%", fontSize: 12 }}
                  >
                    <AgGridReact
                      enableCellTextSelection={true}
                      pagination={true}
                      paginationAutoPageSize={true}
                      rowSelection={"single"}
                      rowData={arrPerfomance || []}
                      defaultColDef={{
                        resizable: true,
                        suppressSizeToFit: true,
                      }}
                      columnDefs={[
                        { field: "message" },
                        { field: "totalCount" },
                        { field: "totalCountACK" },
                        { field: "totalCountNACK" },
                        { field: "minSpeedACK" },
                        { field: "avgSpeedACK" },
                        { field: "maxSpeedACK" },
                        { field: "minSpeedIntegration" },
                        { field: "avgSpeedIntegration" },
                        { field: "maxSpeedIntegration" },
                        { field: "minSpeedRespond" },
                        { field: "avgSpeedRespond" },
                        { field: "maxSpeedRespond" },
                        { field: "statTime" },
                      ]}
                      onCellClicked={(e) => {
                        console.log("cell: ", e);
                      }}
                      onSelectionChanged={(e) => {
                        console.log("row: ", e.api.getSelectedRows()[0]);
                      }}
                    />
                  </div>
                </div>
              </div>
              <div class="tab-pane" id="history">
                <div class="table-responsive"></div>
              </div>
            </div>
          </div>
        </div>
      </Container>
    );
  }
}

const mapStateToProps = (state) => {
  const { user, isLogin } = state.user;
  return {
    user,
    isLogin,
  };
};

export default connect(mapStateToProps, {
  getMessagePerfomance,
})(Perfomance);
