import React, { useEffect } from "react";

import moment from "moment";
import { connect } from "react-redux";
import { AgGridReact } from "ag-grid-react";
import "@ag-grid-community/core/dist/styles/ag-grid.css";
import "@ag-grid-community/core/dist/styles/ag-theme-alpine.css";

import {
  getSystemToSystemMessage,
  getSystemToSystemMessageTypes,
} from "../../redux/action/visualAction";

import imgPC from "../../img/ic_pc.png";
import Sidemenu from "../drawer/Sidemenu";
import Container from "../layout/Container";
import Loader from "../layout/Loader";

const tabs = [
  { title: "System Point of View", id: "#tab_1" },
  { title: "Integration Point of View", id: "#tab_2" },
  // { title: "Process Point of View", id: "#tab_3" },
];

class VisualSystem extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      systems: [],
      systemsHour: [],
      systemsTypes: [],
    };
  }
  componentDidMount() {
    this.onCallGetSystemToSystem();
    this.onCallGetSystemToSystemTypes();
    this.onCallGetSystemToSystemHour();
  }

  //API Call Methods
  onCallGetSystemToSystem = () => {
    const {} = this.state;

    let params = {};
    params.resolution = "H";

    this.setState({ isLoadSystem: true });
    this.props
      .getSystemToSystemMessage(params)
      .then(async (response) => {
        this.setState({ isLoadSystem: false });
        console.log("System response: ", response);
        this.setState({ systems: response.data || [] });
      })
      .catch((err) => {
        this.setState({ isLoadSystem: false });
        console.log("System error: ", err);
      });
  };
  onCallGetSystemToSystemTypes = () => {
    const {} = this.state;

    let params = {};
    params.resolution = "H";

    this.setState({ isLoadSystemTypes: true });
    this.props
      .getSystemToSystemMessageTypes(params)
      .then(async (response) => {
        this.setState({ isLoadSystemTypes: false });
        console.log("System response: ", response);
        this.setState({ systemsTypes: response.data || [] });
      })
      .catch((err) => {
        this.setState({ isLoadSystemTypes: false });
        console.log("System error: ", err);
      });
  };
  onCallGetSystemToSystemHour = () => {
    const {} = this.state;

    let params = {};
    params.resolution = "H";

    this.setState({ isLoadSystem: true });
    this.props
      .getSystemToSystemMessage(params)
      .then(async (response) => {
        this.setState({ isLoadSystem: false });
        console.log("System Hour response: ", response);
        this.setState({ systemsHour: response.data || [] });
      })
      .catch((err) => {
        this.setState({ isLoadSystem: false });
        console.log("System Hour error: ", err);
      });
  };

  render() {
    const {
      isLoadSystem,
      isLoadSystemTypes,
      systems,
      systemsTypes,
      systemsHour,
    } = this.state;
    return (
      <Container>
        <Sidemenu selIndex={5} />
        <div className="content" style={styles.contaienr}>
          {/* Tabs */}
          <div className="list-group main-tab">
            {tabs.map((item, index) => {
              return (
                <a
                  className={
                    index === 0 ? "list-group-item active" : "list-group-item"
                  }
                  data-toggle="list"
                  href={item.id}
                  onClick={() => this.setState({ tabIndex: index })}
                >
                  {item.title}
                </a>
              );
            })}
          </div>

          <div class="tab-content">
            <div className="tab-pane active" id="tab_1">
              <div className="card">
                <div className="box">
                  {isLoadSystem ? (
                    <Loader message={"loading system data"} />
                  ) : null}

                  {systems.map((item, index) => {
                    return (
                      <div
                        class="row align-items-center m-3 mt-4"
                        style={{ width: 650 }}
                      >
                        <div class="col-12 col-md-2">
                          <div class="imgbox">
                            <img
                              src={imgPC}
                              width="100"
                              height="100"
                              alt="Desktop"
                            />
                            <p style={{ marginTop: 4 }}>
                              {item.sendingSystem || ""}
                            </p>
                          </div>
                        </div>

                        <div class="col-12 col-md-8">
                          <div class="greybox">
                            <p>
                              <span class="greyborder"></span>
                              <span class="content-box">
                                {`${item.currentMessage}: ${
                                  item.yaxis_IVALUE || "0"
                                }`}
                              </span>
                              <span class="animated-arrow">
                                <span class="the-arrow right">
                                  <span class="shaft"></span>
                                </span>
                              </span>
                            </p>
                          </div>

                          <div class="greenbox">
                            <p>
                              <span class="animated-arrow">
                                <span class="the-arrow left">
                                  <span class="shaft"></span>
                                </span>
                              </span>
                              <span class="content-box">
                                {/* {`Acknowledge: POS/NEG: ${
                                  item.yaxis_IVALUE2 || "0"
                                  }/`} */}
                                {`Acknowledge: POS/NEG: ${
                                  item.yaxis_IVALUE2 || "0"
                                }/`}
                                <span
                                  class="content-box"
                                  style={{ color: "red" }}
                                >
                                  {`${item.yaxis_IVALUE3 || "0"}`}
                                </span>
                              </span>

                              <span class="greenborder"></span>
                            </p>
                          </div>
                        </div>

                        <div class="col-12 col-md-2">
                          <div class="imgbox">
                            <img
                              src={imgPC}
                              width="100"
                              height="100"
                              alt="Desktop"
                            />
                            <p style={{ marginTop: 4 }}>
                              {item.receivingSystem || ""}
                            </p>
                          </div>
                        </div>
                      </div>
                    );
                  })}

                  {/* <div className="list-group min-tab">
                    {miniTab.map((item, index) => {
                      return (
                        <a
                          className={
                            index === 0
                              ? "list-group-item active"
                              : "list-group-item"
                          }
                          data-toggle="list"
                          href={item.id}
                          onClick={() => this.setState({ miniTabIndex: index })}
                        >
                          {item.title}
                        </a>
                      );
                    })}
                  </div> */}

                  {/* <div className="tab-content">
                    <div className="tab-pane active" id="min_tab_1">
                      
                    </div>
                    <div className="tab-pane active" id="min_tab_2"></div>
                  </div> */}
                </div>
              </div>
            </div>

            <div className="tab-pane" id="tab_2">
              <div className="card">
                <div className="box">
                  {isLoadSystemTypes ? (
                    <Loader message={"loading system data"} />
                  ) : null}

                  {systemsTypes.map((item, index) => {
                    return (
                      <div
                        class="row align-items-center m-3 mt-4"
                        style={{ width: 650 }}
                      >
                        <div class="col-12 col-md-2">
                          <div class="imgbox">
                            <img
                              src={imgPC}
                              width="100"
                              height="100"
                              alt="Desktop"
                            />
                            <p style={{ marginTop: 4 }}>
                              {item.sendingSystem || ""}
                            </p>
                          </div>
                        </div>

                        <div class="col-12 col-md-8">
                          <div class="greybox">
                            <p>
                              <span class="greyborder"></span>
                              <span class="content-box">
                                {`${item.currentMessage}: ${
                                  item.yaxis_IVALUE || "0"
                                }`}
                              </span>
                              <span class="animated-arrow">
                                <span class="the-arrow right">
                                  <span class="shaft"></span>
                                </span>
                              </span>
                            </p>
                          </div>

                          <div class="greenbox">
                            <p>
                              <span class="animated-arrow">
                                <span class="the-arrow left">
                                  <span class="shaft"></span>
                                </span>
                              </span>
                              <span class="content-box">
                                {/* {`Acknowledge: POS/NEG: ${
                                  item.yaxis_IVALUE2 || "0"
                                  }/`} */}
                                {`${item.yaxis_IVALUE2 || "0"}/`}
                                <span
                                  class="content-box"
                                  style={{ color: "red" }}
                                >
                                  {`${item.yaxis_IVALUE3 || "0"}`}
                                </span>
                              </span>

                              <span class="greenborder"></span>
                            </p>
                          </div>

                          {/* <div class="greenbox">
                            <p>
                              <span class="animated-arrow">
                                <span class="the-arrow left">
                                  <span class="shaft"></span>
                                </span>
                              </span>
                              <span class="content-box">
                                {`Acknowledge: POS/NEG: ${
                                  item.yaxis_IVALUE2 || "0"
                                }/`}
                                <span
                                  class="content-box"
                                  style={{ color: "red" }}
                                >
                                  {`${item.yaxis_IVALUE3 || "0"}`}
                                </span>
                              </span>

                              <span class="greenborder"></span>
                            </p>
                          </div> */}
                        </div>

                        <div class="col-12 col-md-2">
                          <div class="imgbox">
                            <img
                              src={imgPC}
                              width="100"
                              height="100"
                              alt="Desktop"
                            />
                            <p style={{ marginTop: 4 }}>
                              {item.receivingSystem || ""}
                            </p>
                          </div>
                        </div>
                      </div>
                    );
                  })}

                  {/* <div className="list-group min-tab">
                    {miniTab.map((item, index) => {
                      return (
                        <a
                          className={
                            index === 0
                              ? "list-group-item active"
                              : "list-group-item"
                          }
                          data-toggle="list"
                          href={item.id}
                          onClick={() => this.setState({ miniTabIndex: index })}
                        >
                          {item.title}
                        </a>
                      );
                    })}
                  </div> */}

                  {/* <div className="tab-content">
                    <div className="tab-pane active" id="min_tab_1">
                      
                    </div>
                    <div className="tab-pane active" id="min_tab_2"></div>
                  </div> */}
                </div>
              </div>
            </div>

            <div className="tab-pane" id="tab_3"></div>
          </div>
        </div>
      </Container>
    );
  }
}

const styles = {
  contaienr: {
    display: "flex",
    flexDirection: "column",
  },
  label: {
    marginLeft: 8,
    marginRight: 8,
    minWidth: 30,
  },
};

const mapStateToProps = (state) => {
  const { user, isLogin } = state.user;
  return {
    user,
    isLogin,
  };
};

export default connect(mapStateToProps, {
  getSystemToSystemMessage,
  getSystemToSystemMessageTypes,
})(VisualSystem);
