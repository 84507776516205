import { makeRequest } from "../../api/apiCall";
import { login } from "../../api/apiConstant";
import { LOGIN_DETAILS } from "./types";

//Login & Register Actions
export const loginUser = (userData) => {
  return (dispatch, getState) => {
    try {
      return makeRequest(login, "post", userData)
        .then((response) => {
          console.log("Login Data: " + response.data);
          if (response.data && response.data === true) {
            let user = userData;
            dispatch({
              type: LOGIN_DETAILS,
              payload: { user, isLogin: true },
            });
            return Promise.resolve({
              status: true,
              data: user,
              message: "Login sucessfully !!",
            });
          } else {
            return Promise.resolve({
              status: false,
              data: {},
              message: "Login failed",
            });
          }
        })
        .catch((error) => {
          console.log("Error: ", error);
          return Promise.reject(error);
        });
    } catch (e) {
      return Promise.reject(e);
    }
  };
};
