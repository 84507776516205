import { makeRequest } from "../../api/apiCall";
import {
  messageAcknowledgeList,
  messageCategories,
  messageTypeList,
  messageValidationList,
  resolutionList,
  searchTypeList,
  systems,
  users,
  watchdogCategory,
  watchdogProcess,
} from "../../api/apiConstant";

//Metadata Actions
export const getUsers = (userData) => {
  return (dispatch, getState) => {
    try {
      return makeRequest(users, "get", userData)
        .then((response) => {
          console.log("Users Data: " + response.data);
          return Promise.resolve({
            status: true,
            data: response.data || [],
            message: "",
          });
        })
        .catch((error) => {
          console.log("Error: ", error);
          return Promise.reject(error);
        });
    } catch (e) {
      return Promise.reject(e);
    }
  };
};

export const getSystems = (userData) => {
  return (dispatch, getState) => {
    try {
      return makeRequest(systems, "get", userData)
        .then((response) => {
          console.log("System Data: " + response.data);
          return Promise.resolve({
            status: true,
            data: response.data || [],
            message: "",
          });
        })
        .catch((error) => {
          console.log("Error: ", error);
          return Promise.reject(error);
        });
    } catch (e) {
      return Promise.reject(e);
    }
  };
};

export const getMessageValidation = (userData) => {
  return (dispatch, getState) => {
    try {
      return makeRequest(messageValidationList, "get", userData)
        .then((response) => {
          console.log("Validation Data: " + response.data);
          return Promise.resolve({
            status: true,
            data: response.data || [],
            message: "",
          });
        })
        .catch((error) => {
          console.log("Error: ", error);
          return Promise.reject(error);
        });
    } catch (e) {
      return Promise.reject(e);
    }
  };
};

export const getMessageTypeList = (userData) => {
  return (dispatch, getState) => {
    try {
      return makeRequest(messageTypeList, "get", userData)
        .then((response) => {
          console.log("Message Type Data: " + response.data);
          return Promise.resolve({
            status: true,
            data: response.data || [],
            message: "",
          });
        })
        .catch((error) => {
          console.log("Error: ", error);
          return Promise.reject(error);
        });
    } catch (e) {
      return Promise.reject(e);
    }
  };
};

export const getMessageAcknowledgeList = (userData) => {
  return (dispatch, getState) => {
    try {
      return makeRequest(messageAcknowledgeList, "get", userData)
        .then((response) => {
          console.log("Acknowledge Data: " + response.data);
          return Promise.resolve({
            status: true,
            data: response.data || [],
            message: "",
          });
        })
        .catch((error) => {
          console.log("Error: ", error);
          return Promise.reject(error);
        });
    } catch (e) {
      return Promise.reject(e);
    }
  };
};

export const getSearchTypeList = (userData) => {
  return (dispatch, getState) => {
    try {
      return makeRequest(searchTypeList, "get", userData)
        .then((response) => {
          console.log("Search Data: " + response.data);
          return Promise.resolve({
            status: true,
            data: response.data || [],
            message: "",
          });
        })
        .catch((error) => {
          console.log("Error: ", error);
          return Promise.reject(error);
        });
    } catch (e) {
      return Promise.reject(e);
    }
  };
};

export const getMessageCategories = (userData) => {
  return (dispatch, getState) => {
    try {
      return makeRequest(messageCategories, "get", userData)
        .then((response) => {
          console.log("Categories Data: " + response.data);
          return Promise.resolve({
            status: true,
            data: response.data || [],
            message: "",
          });
        })
        .catch((error) => {
          console.log("Error: ", error);
          return Promise.reject(error);
        });
    } catch (e) {
      return Promise.reject(e);
    }
  };
};

export const getResolutions = (userData) => {
  return (dispatch, getState) => {
    try {
      return makeRequest(resolutionList, "get", userData)
        .then((response) => {
          console.log("Resolution Data: " + response.data);
          return Promise.resolve({
            status: true,
            data: response.data || [],
            message: "",
          });
        })
        .catch((error) => {
          console.log("Error: ", error);
          return Promise.reject(error);
        });
    } catch (e) {
      return Promise.reject(e);
    }
  };
};

export const getWatchdogCategory = (userData) => {
  return (dispatch, getState) => {
    try {
      return makeRequest(watchdogCategory, "get", userData)
        .then((response) => {
          console.log("WatchdogCategory Data: " + response.data);
          return Promise.resolve({
            status: true,
            data: response.data || [],
            message: "",
          });
        })
        .catch((error) => {
          console.log("Error: ", error);
          return Promise.reject(error);
        });
    } catch (e) {
      return Promise.reject(e);
    }
  };
};

export const getWatchdogProcess = (userData) => {
  return (dispatch, getState) => {
    try {
      return makeRequest(watchdogProcess + "/" + userData.id, "get", {})
        .then((response) => {
          console.log("WatchdogCategory Data: " + response.data);
          return Promise.resolve({
            status: true,
            data: response.data || [],
            message: "",
          });
        })
        .catch((error) => {
          console.log("Error: ", error);
          return Promise.reject(error);
        });
    } catch (e) {
      return Promise.reject(e);
    }
  };
};
