import React from "react";

import moment from "moment";
import { connect } from "react-redux";
import { AgGridReact } from "ag-grid-react";
import exportFromJSON from "export-from-json";

import iconXLS from "../../img/ic_xls.png";
import "@ag-grid-community/core/dist/styles/ag-grid.css";
import "@ag-grid-community/core/dist/styles/ag-theme-alpine.css";

import { getValues } from "../../redux/action/valueAction";

import Sidemenu from "../drawer/Sidemenu";
import Container from "../layout/Container";
import Loader from "../layout/Loader";

const tabs = [
  { title: "Values", id: "#tab_1" },
  { title: "Value Graph", id: "#tab_2" },
  { title: "Consumption Graph", id: "#tab_3" },
];

const miniTab = [
  { title: "Values", id: "#min_tab_1" },
  { title: "Row Values", id: "#min_tab_2" },
];

class Value extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      txtFrom: "",
      txtTo: "",
      txtDeliverysiteCode: "",
      txtSVKCode: "",
      txtNetwork: "",
      txtConcession: "",
      miniTabIndex: 0,
      isLoadValues: false,
      values: [],
    };
  }
  componentDidMount() {}

  //Export Methods
  exportToXLS = (data, fileName) => {
    const exportType = "xls";
    exportFromJSON({ data, fileName, exportType });
  };

  //API Call Methods
  onCallGetValues = () => {
    const {
      txtFrom,
      txtTo,
      txtSVKCode,
      txtNetwork,
      txtDeliverysiteCode,
      txtConcession,
    } = this.state;

    let params = {};
    if (txtFrom) params.fromDate = moment(txtFrom).format("YYYY-MM-DD");
    if (txtTo) params.toDate = moment(txtTo).format("YYYY-MM-DD");
    if (txtDeliverysiteCode) params.deliverySiteEanCode = txtDeliverysiteCode;

    console.log("params: ", params);

    this.setState({ isLoadValues: true });
    this.props
      .getValues(params)
      .then(async (response) => {
        this.setState({ isLoadValues: false });
        console.log("Values response: ", response);
        this.manageValues(response.data || []);
      })
      .catch((err) => {
        this.setState({ isLoadValues: false });
        console.log("Values error: ", err);
      });
  };

  //Data Methods
  manageValues = (data) => {
    let arrValues = [];
    for (const obj of data) {
      let dict = {};
      dict.concesssion = "";
      dict.network = "";
      dict.deliverysiteEancode = obj.deliverySiteEanCode;
      dict.meter = obj.meterValueId;
      dict.svkCode = "";
      dict.readingTime = obj.readingDateTime;
      dict.reading = obj.reading;
      dict.externalMeterValueId = obj.extMeterValueId;
      dict.unit = obj.unit;
      dict.readingTypeId = obj.readingTypeId;
      dict.readingReasonId = obj.readingReasonId;
      dict.readingStatusId = obj.readingStatusId;
      dict.extReadingStatus = obj.extReadingStatus;
      dict.messageId = obj.messageId;
      dict.requestId = obj.requestId;
      dict.requestedTime = obj.requestedReadingDate;
      arrValues.push(dict);
    }
    this.setState({ values: arrValues });
  };

  render() {
    const {
      txtFrom,
      txtTo,
      txtDeliverysiteCode,
      txtSVKCode,
      txtNetwork,
      txtConcession,
      isLoadValues,
      values,
    } = this.state;
    return (
      <Container>
        <Sidemenu selIndex={7} />
        <div className="content" style={styles.contaienr}>
          {/* Tabs */}
          <div className="list-group main-tab">
            {tabs.map((item, index) => {
              return (
                <a
                  className={
                    index === 0 ? "list-group-item active" : "list-group-item"
                  }
                  data-toggle="list"
                  href={item.id}
                  onClick={() => this.setState({ tabIndex: index })}
                >
                  {item.title}
                </a>
              );
            })}
          </div>

          <div class="tab-content">
            <div className="tab-pane active" id="tab_1">
              <div className="row align-items-center mb-2">
                <div className="col-md-6">
                  <div className="box">
                    <div className="row align-items-center">
                      <div style={styles.label}>
                        <label className="mb-0">From</label>
                      </div>
                      <div className="col-4 col-md-2">
                        <div className="date-select">
                          <input
                            type="date"
                            name="from"
                            placeholder=""
                            className="form-control date"
                            value={txtFrom}
                            onChange={(e) =>
                              this.setState({
                                txtFrom: e.target.value,
                              })
                            }
                          />
                        </div>
                      </div>

                      <div style={styles.label}>
                        <label className="mb-0">To</label>
                      </div>
                      <div className="col-4 col-md-2">
                        <div className="date-select">
                          <input
                            type="date"
                            name="from"
                            placeholder=""
                            className="form-control date"
                            value={txtTo}
                            onChange={(e) =>
                              this.setState({
                                txtTo: e.target.value,
                              })
                            }
                          />
                        </div>
                      </div>

                      <div className="col-6 col-md-3 mb-2 mb-lg-0">
                        <div className="form-group mb-md-0">
                          <input
                            type="text"
                            className="form-control"
                            placeholder="Deliverysite Ean code"
                            value={txtDeliverysiteCode}
                            onChange={(e) =>
                              this.setState({
                                txtDeliverysiteCode: e.target.value,
                              })
                            }
                          />
                        </div>
                      </div>

                      {/* <div className="col-6 col-md-3 mb-2 mb-lg-0">
                        <div className="form-group mb-md-0">
                          <input
                            type="text"
                            className="form-control"
                            placeholder="SVK Code"
                            value={txtSVKCode}
                            onChange={(e) =>
                              this.setState({
                                txtSVKCode: e.target.value,
                              })
                            }
                          />
                        </div>
                      </div> */}

                      <div className="col-md-2">
                        <button
                          className="btn btn-primary btn-block btn-sm"
                          onClick={() => this.onCallGetValues()}
                        >
                          Search
                        </button>
                      </div>

                      <div className="col-md-5" style={{ marginTop: 8 }}>
                        <b>Number Of Results : {values.length}</b>
                        <br />
                        <b>Number Of Raw Value Results : 0</b>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div className="box">
                <div className="list-group min-tab">
                  {miniTab.map((item, index) => {
                    return (
                      <a
                        className={
                          index === 0
                            ? "list-group-item active"
                            : "list-group-item"
                        }
                        data-toggle="list"
                        href={item.id}
                        onClick={() => this.setState({ miniTabIndex: index })}
                      >
                        {item.title}
                      </a>
                    );
                  })}
                </div>

                <div className="tab-content">
                  <div className="tab-pane active" id="min_tab_1">
                    <div className="table-responsive">
                      {/* Message Table */}
                      {isLoadValues ? (
                        <Loader message={"loading values data"} />
                      ) : null}
                      {/* Download XLS */}
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "flex-end",
                        }}
                      >
                        <img
                          src={iconXLS}
                          style={{ width: 20, height: 20, marginBottom: 8 }}
                          alt="export_xls"
                          title={"Export XLS"}
                          onClick={() =>
                            this.exportToXLS(values, "values")
                          }
                        />
                      </div>
                      <div
                        className="ag-theme-alpine"
                        style={{ height: 600, width: "100%", fontSize: 12 }}
                      >
                        <AgGridReact
                          enableCellTextSelection={true}
                          pagination={true}
                          paginationAutoPageSize={true}
                          rowSelection={"single"}
                          rowData={values}
                          columnDefs={[
                            { field: "concesssion" },
                            { field: "network" },
                            { field: "deliverysiteEancode" },
                            { field: "meter" },
                            { field: "svkCode" },
                            { field: "readingTime" },
                            { field: "reading" },
                            { field: "externalMeterValueId" },
                            { field: "unit" },
                            { field: "readingTypeId" },
                            { field: "readingReasonId" },
                            { field: "readingStatusId" },
                            { field: "extReadingStatus" },
                            { field: "messageId" },
                            { field: "requestId" },
                            { field: "requestedTime" },
                          ]}
                          defaultColDef={{ resizable: true, sortable: true }}
                          onSelectionChanged={(e) => {}}
                        ></AgGridReact>
                      </div>
                    </div>
                  </div>
                  <div className="tab-pane active" id="min_tab_2"></div>
                </div>
              </div>
            </div>

            <div className="tab-pane" id="tab_2"></div>
            <div className="tab-pane" id="tab_3"></div>
          </div>
        </div>
      </Container>
    );
  }
}

const styles = {
  contaienr: {
    display: "flex",
    flexDirection: "column",
  },
  label: {
    marginLeft: 8,
    marginRight: 8,
    minWidth: 30,
  },
};

const mapStateToProps = (state) => {
  const { user, isLogin } = state.user;
  return {
    user,
    isLogin,
  };
};

export default connect(mapStateToProps, {
  getValues,
})(Value);
