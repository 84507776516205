import React from "react";
import { Modal } from "react-bootstrap";

const Sql = (props) => {
  return (
    <Modal show={props.show} onHide={props.handleClose}>
      <Modal.Body>
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title">{"SQL"}</h5>
            <button
              type="button"
              class="close"
              data-dismiss="modal"
              aria-label="Close"
              onClick={props.handleClose}
            >
              {" "}
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div class="modal-body">
            <div class="data-box">{props.query || ""}</div>
          </div>
        </div>
      </Modal.Body>
    </Modal>
  );
};

export default Sql;
