import React from "react";

import moment from "moment";
import { connect } from "react-redux";
import { AgGridReact } from "ag-grid-react";
import "@ag-grid-community/core/dist/styles/ag-grid.css";
import "@ag-grid-community/core/dist/styles/ag-theme-alpine.css";

import {
  getActiveProcess,
  getMessageEndpoint,
} from "../../redux/action/adminAction";
import {
  getMessageTypeList,
  getSystems,
  getUsers,
} from "../../redux/action/metadataAction";

import Sidemenu from "../drawer/Sidemenu";
import Container from "../layout/Container";
import Loader from "../layout/Loader";

const tabs = [
  { title: "System", id: "#tab_1" },
  { title: "Message Type", id: "#tab_2" },
  { title: "Message Endpoint", id: "#tab_3" },
  { title: "Message Store Modes", id: "#tab_4" },
  { title: "Message Category Types", id: "#tab_5" },
  { title: "Message Integration Types", id: "#tab_6" },
  { title: "Message Platform Types", id: "#tab_7" },
  { title: "Groups", id: "#tab_8" },
  { title: "User Administrations", id: "#tab_9" },
  { title: "Active Processes", id: "#tab_10" },
];

class Administration extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      activeProcess: [],
      endpoints: [],
      messageTypes: [],
      systems: [],
      users: [],
      txtBatchUniqueId: "",
      txtBatch: "",
      txtStartDate: "",
      txtEndDate: "",
      tabIndex: 0,
      isLoadTypes: false,
      isLoadActive: false,
      isLoadEndpoints: false,
      isLoadSystem: false,
      isLoadUsers: false,
    };
  }
  componentDidMount() {
    this.onCallGetActiveProcess();
    this.onCallGetMessageEndpoint();
    this.onCallGetMessageTypes();
    this.onCallGetSystem();
    this.onCallGetUsers();
  }

  //API Call Methods
  onCallGetActiveProcess = () => {
    let params = {};
    this.setState({ isLoadActive: true });
    this.props
      .getActiveProcess(params)
      .then(async (response) => {
        this.setState({ isLoadActive: false });
        console.log("Active Process response: ", response);
        this.setState({ activeProcess: response.data || [] });
      })
      .catch((err) => {
        this.setState({ isLoadActive: false });
        console.log("Active Process error: ", err);
      });
  };
  onCallGetMessageEndpoint = () => {
    let params = {};
    this.setState({ isLoadEndpoints: true });
    this.props
      .getMessageEndpoint(params)
      .then(async (response) => {
        this.setState({ isLoadEndpoints: false });
        console.log("Message Endpoint response: ", response);
        this.manageEndpoints(response.data || []);
      })
      .catch((err) => {
        this.setState({ isLoadEndpoints: false });
        console.log("Message Endpoint error: ", err);
      });
  };
  onCallGetMessageTypes = () => {
    let params = {};
    this.setState({ isLoadTypes: true });
    this.props
      .getMessageTypeList(params)
      .then(async (response) => {
        this.setState({ isLoadTypes: false });
        console.log("Message Types response: ", response);
        this.setState({ messageTypes: response.data || [] });
      })
      .catch((err) => {
        this.setState({ isLoadTypes: false });
        console.log("Message Types error: ", err);
      });
  };
  onCallGetSystem = () => {
    let params = {};
    this.setState({ isLoadSystem: true });
    this.props
      .getSystems(params)
      .then(async (response) => {
        this.setState({ isLoadSystem: false });
        console.log("System response: ", response);
        this.setState({ systems: response.data || [] });
      })
      .catch((err) => {
        this.setState({ isLoadSystem: false });
        console.log("System error: ", err);
      });
  };
  onCallGetUsers = () => {
    let params = {};
    this.setState({ isLoadUsers: true });
    this.props
      .getUsers(params)
      .then(async (response) => {
        this.setState({ isLoadUsers: false });
        console.log("Users response: ", response);
        this.manageUsers(response.data || []);
      })
      .catch((err) => {
        this.setState({ isLoadUsers: false });
        console.log("Users error: ", err);
      });
  };

  //Data Methods
  manageUsers = (data) => {
    let arrUsers = [];
    for (const obj of data) {
      let dict = {};
      dict.userId = obj.userId;
      dict.userName = obj.userName;
      dict.firstName = obj.userFirstName;
      dict.lastName = obj.userSureName;
      arrUsers.push(dict);
    }
    this.setState({ users: arrUsers });
  };
  manageEndpoints = (data) => {
    let arrEndpoints = [];
    for (const obj of data) {
      let dict = {};
      dict.messageType = obj.messageType;
      dict.endpointName = obj.messageEndPointName;
      dict.endpointDestination = obj.messageEndpointPathDest;
      dict.endpointPathSource = obj.messageEndpointPathSource;
      dict.startDate = obj.endpointStart;
      dict.endDate = obj.endpointEnd;
      dict.active = obj.active;
      dict.graceTime = obj.graceTime;
      arrEndpoints.push(dict);
    }
    this.setState({ endpoints: arrEndpoints });
  };

  render() {
    const {
      isLoadActive,
      isLoadEndpoints,
      isLoadSystem,
      isLoadTypes,
      isLoadUsers,
      activeProcess,
      endpoints,
      systems,
      users,
      messageTypes,
      txtBatchUniqueId,
      txtBatch,
      txtStartDate,
      txtEndDate,
    } = this.state;
    return (
      <Container>
        <Sidemenu selIndex={13} />
        <div className="content" style={styles.contaienr}>
          {/* Tabs */}
          <div className="list-group main-tab">
            {tabs.map((item, index) => {
              return (
                <a
                  className={
                    index === 0 ? "list-group-item active" : "list-group-item"
                  }
                  data-toggle="list"
                  href={item.id}
                  onClick={() => this.setState({ tabIndex: index })}
                >
                  {item.title}
                </a>
              );
            })}
          </div>

          <div class="tab-content">
            <div className="tab-pane active" id="tab_1">
              <div className="table-responsive">
                {isLoadSystem ? (
                  <Loader message={"loading system data"} />
                ) : null}
                <div
                  className="ag-theme-alpine"
                  style={{ height: 550, width: "100%", fontSize: 12 }}
                >
                  <AgGridReact
                    enableCellTextSelection={true}
                    pagination={true}
                    paginationAutoPageSize={true}
                    rowSelection={"single"}
                    rowData={systems}
                    columnDefs={[
                      { field: "systemId" },
                      { field: "systemName" },
                      { field: "systemMessagePrefix" },
                    ]}
                    defaultColDef={{ resizable: true, sortable: true }}
                    onSelectionChanged={(e) => {}}
                  />
                </div>
              </div>
            </div>
            <div className="tab-pane" id="tab_2">
              <div className="table-responsive">
                {isLoadTypes ? (
                  <Loader message={"loading message types data"} />
                ) : null}
                <div
                  className="ag-theme-alpine"
                  style={{ height: 550, width: "100%", fontSize: 12 }}
                >
                  <AgGridReact
                    enableCellTextSelection={true}
                    pagination={true}
                    paginationAutoPageSize={true}
                    rowSelection={"single"}
                    rowData={messageTypes}
                    columnDefs={[
                      { field: "messageType" },
                      { field: "messageCategory" },
                    ]}
                    defaultColDef={{ resizable: true, sortable: true }}
                    onSelectionChanged={(e) => {}}
                  />
                </div>
              </div>
            </div>

            <div className="tab-pane" id="tab_3">
              <div className="table-responsive">
                {isLoadEndpoints ? (
                  <Loader message={"loading endpoint data"} />
                ) : null}
                <div
                  className="ag-theme-alpine"
                  style={{ height: 550, width: "100%", fontSize: 12 }}
                >
                  <AgGridReact
                    enableCellTextSelection={true}
                    pagination={true}
                    paginationAutoPageSize={true}
                    rowSelection={"single"}
                    rowData={endpoints}
                    columnDefs={[
                      { field: "messageType" },
                      { field: "endpointName" },
                      { field: "endpointDestination" },
                      { field: "endpointPathSource" },
                      { field: "startDate" },
                      { field: "endDate" },
                      { field: "active" },
                      { field: "graceTime" },
                    ]}
                    defaultColDef={{ resizable: true, sortable: true }}
                    onSelectionChanged={(e) => {}}
                  />
                </div>
              </div>
            </div>

            <div className="tab-pane" id="tab_4"></div>
            <div className="tab-pane" id="tab_5"></div>
            <div className="tab-pane" id="tab_6"></div>
            <div className="tab-pane" id="tab_7"></div>
            <div className="tab-pane" id="tab_8"></div>

            <div className="tab-pane" id="tab_9">
              <div className="table-responsive">
                {isLoadUsers ? <Loader message={"loading users data"} /> : null}
                <div
                  className="ag-theme-alpine"
                  style={{ height: 550, width: "100%", fontSize: 12 }}
                >
                  <AgGridReact
                    enableCellTextSelection={true}
                    pagination={true}
                    paginationAutoPageSize={true}
                    rowSelection={"single"}
                    rowData={users}
                    columnDefs={[
                      { field: "userId" },
                      { field: "userName" },
                      { field: "firstName" },
                      { field: "lastName" },
                    ]}
                    defaultColDef={{ resizable: true, sortable: true }}
                    onSelectionChanged={(e) => {}}
                  />
                </div>
              </div>
            </div>

            <div className="tab-pane" id="tab_10">
              <div className="table-responsive">
                {/* Active Process Table */}
                {isLoadActive ? (
                  <Loader message={"loading active process data"} />
                ) : null}
                <div
                  className="ag-theme-alpine"
                  style={{ height: 550, width: "100%", fontSize: 12 }}
                >
                  <AgGridReact
                    enableCellTextSelection={true}
                    pagination={true}
                    paginationAutoPageSize={true}
                    rowSelection={"single"}
                    rowData={activeProcess}
                    columnDefs={[
                      { field: "batchUniqueId" },
                      { field: "batch" },
                      { field: "startDate" },
                      { field: "endDate" },
                    ]}
                    defaultColDef={{ resizable: true, sortable: true }}
                    onSelectionChanged={(e) => {}}
                  />
                </div>

                <div style={{ flexDirection: "row", marginTop: 10 }}>
                  <button
                    style={{ width: 130 }}
                    className="btn btn-primary btn-sm"
                    onClick={() => {}}
                  >
                    Stop Process
                  </button>
                  <button
                    style={{ width: 100 }}
                    className="btn btn-primary btn-sm"
                    onClick={() => this.onCallGetActiveProcess()}
                  >
                    Reload
                  </button>
                </div>
              </div>

              <div style={{ marginTop: 14, marginLeft: 10 }}>
                <div className="row align-items-center">
                  <div style={styles.label}>
                    <label className="mb-0">Batch Unique ID</label>
                  </div>
                  <div className="col-4 col-md-2">
                    <div className="date-select">
                      <input
                        name="from"
                        placeholder=""
                        className="form-control date"
                        value={txtBatchUniqueId}
                        onChange={(e) =>
                          this.setState({
                            txtBatchUniqueId: e.target.value,
                          })
                        }
                      />
                    </div>
                  </div>
                </div>
                <div
                  className="row align-items-center"
                  style={{ marginTop: 8 }}
                >
                  <div style={styles.label}>
                    <label className="mb-0">Batch</label>
                  </div>
                  <div className="col-4 col-md-2">
                    <div className="date-select">
                      <input
                        name="from"
                        placeholder=""
                        className="form-control date"
                        value={txtBatch}
                        onChange={(e) =>
                          this.setState({
                            txtBatch: e.target.value,
                          })
                        }
                      />
                    </div>
                  </div>
                </div>
                <div
                  className="row align-items-center"
                  style={{ marginTop: 8 }}
                >
                  <div style={styles.label}>
                    <label className="mb-0">Start Date</label>
                  </div>
                  <div className="col-4 col-md-2">
                    <div className="date-select">
                      <input
                        name="startDate"
                        placeholder=""
                        type="date"
                        className="form-control date"
                        value={txtStartDate}
                        onChange={(e) =>
                          this.setState({
                            txtStartDate: e.target.value,
                          })
                        }
                      />
                    </div>
                  </div>
                </div>
                <div
                  className="row align-items-center"
                  style={{ marginTop: 8 }}
                >
                  <div style={styles.label}>
                    <label className="mb-0">End Date</label>
                  </div>
                  <div className="col-4 col-md-2">
                    <div className="date-select">
                      <input
                        type="date"
                        name="endDate"
                        placeholder=""
                        className="form-control date"
                        value={txtEndDate}
                        onChange={(e) =>
                          this.setState({
                            txtEndDate: e.target.value,
                          })
                        }
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Container>
    );
  }
}

const styles = {
  contaienr: {
    display: "flex",
    flexDirection: "column",
  },
  label: {
    fontSize: 12,
    fontWeight: "600",
    minWidth: 120,
  },
};

const mapStateToProps = (state) => {
  const { user, isLogin } = state.user;
  return {
    user,
    isLogin,
  };
};

export default connect(mapStateToProps, {
  getActiveProcess,
  getMessageEndpoint,
  getMessageTypeList,
  getSystems,
  getUsers,
})(Administration);
