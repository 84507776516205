import React from "react";

const Loader = (props) => {
  return (
    <div
      style={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        marginBottom: 8,
      }}
    >
      <span
        className="spinner-border spinner-border-sm"
        role="status"
        aria-hidden="true"
      />
      <div style={{ marginLeft: 8, fontWeight: "bold" }}>{props.message}</div>
    </div>
  );
};

export default Loader;
